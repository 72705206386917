import { memo, useCallback, useMemo } from "react";
import { Platform } from "react-native";
import { Clusterer } from "@react-google-maps/marker-clusterer";
import { Marker as WebMarker } from "@react-google-maps/api";
import { Marker as MobileMarker } from "react-native-maps";

import useThemeName from "../../../hooks/useThemeName";
import { Mappable } from "./types";

export type IsomorphicMarkerProps<T extends Mappable> = {
  item: T;
  onClick: (item: T) => void;
  isSelected: boolean;
  clusterer?: Clusterer;
  color: string;

  // HACK: react-native-map-cluster checks for the presence of this prop, so
  // we have to include it, even though we can calculate it from the
  // business.
  coordinate?: {
    latitude: number;
    longitude: number;
  };
};

type AppMarkerProps = {
  opacity: number;
  color: string;
  onClick: () => void;
  isSelected: boolean;
  coordinate: {
    latitude: number;
    longitude: number;
  };
  clusterer?: Clusterer;
};

// eslint-disable-next-line max-len
const markerSVGPath = "M14 0C6.26 0 0 6.26 0 14C0 24.5 14 40 14 40C14 40 28 24.5 28 14C28 6.26 21.74 0 14 0ZM14 19C11.24 19 9 16.76 9 14C9 11.24 11.24 9 14 9C16.76 9 19 11.24 19 14C19 16.76 16.76 19 14 19Z";

const WebAppMarker = memo(function WebAppMarker({
  color,
  opacity,
  onClick,
  clusterer,
  isSelected,
  coordinate,
}: AppMarkerProps) {
  const themeName = useThemeName();
  const animation = isSelected ? google.maps.Animation.BOUNCE : undefined;

  const position = useMemo(() => ({
    lat: coordinate.latitude,
    lng: coordinate.longitude,
  }), [coordinate.latitude, coordinate.longitude]);

  const mapIcon = useMemo(() => ({
    path: markerSVGPath,
    fillColor: color,
    fillOpacity: 1,
    strokeWeight: 1,
    strokeColor: themeName === "dark" ? "white" : "black",
    rotation: 0,
    scale: 1,
    // these both need anchors
    anchor: new google.maps.Point(14, 40),
    size: new google.maps.Size(28, 40),
  }), [color, themeName]);

  return (
    <WebMarker
      position={position}
      clusterer={clusterer}
      icon={mapIcon}
      onClick={onClick}
      opacity={opacity}
      noClustererRedraw={true}
      animation={animation}
    />
  );
});

const MobileAppMarker = ({
  color,
  opacity,
  onClick,
  coordinate,
}: AppMarkerProps) => (
  <MobileMarker
    coordinate={coordinate}
    opacity={opacity}
    onPress={onClick}
    pinColor={color}
    flat
  />
);

const IsomorphicMarker = <T extends Mappable>({
  item,
  isSelected,
  onClick,
  clusterer,
  color,
  coordinate = {
    latitude: Number(item.latitude),
    longitude: Number(item.longitude),
  },
}: IsomorphicMarkerProps<T>) => {
  const handleClick = useCallback(() => {
    onClick(item);
  }, [onClick, item]);

  const opacity = isSelected ? 1 : 0.5;

  if (Platform.OS === "web") {
    return (
      <WebAppMarker
        color={color}
        opacity={opacity}
        onClick={handleClick}
        clusterer={clusterer}
        isSelected={isSelected}
        coordinate={coordinate}
      />
    );
  }

  return (
    <MobileAppMarker
      key={item.id}
      color={color}
      opacity={opacity}
      onClick={handleClick}
      isSelected={isSelected}
      coordinate={coordinate}
    />
  );
};

export default IsomorphicMarker;
