import { useEffect, FC } from "react";

import { useUserDoc } from "@reasongcp/react-fire-sub";

import useAssigneeSubscribe from "../../hooks/useAssigneeSubscribe";
import { useAppDispatch } from "../../redux/hooks";
import { subscribeBusinessesToFirestore } from "../../redux/businessListSlice";
import { subscribeResidencesToFirestore } from "../../redux/residenceListSlice";

import SubjectMap from "../../components/SubjectMap";
import BusinessListNavigation from "../../components/BusinessListNavigation";
import ResidenceListNavigation from "../../components/ResidenceListNavigation";
import useRenderMemoizedComponent from "../../hooks/useRenderMemoizedComponent";

import MapScreen from "./MapScreen";

const BusinessMapScreen: FC = () => {
  const dispatch = useAppDispatch();
  const userDoc = useUserDoc();
  const currentOrgId = userDoc?.currentOrganization;

  useEffect(() => {
    if (!currentOrgId) return;

    const sub = dispatch(subscribeBusinessesToFirestore(currentOrgId));
    return () => sub.unsubscribe();
  }, [currentOrgId, dispatch]);

  useAssigneeSubscribe();

  const renderList = useRenderMemoizedComponent(BusinessListNavigation);
  const renderMap = useRenderMemoizedComponent(SubjectMap.Businesses);

  return <MapScreen renderList={renderList} renderMap={renderMap} />;
};

const ResidenceMapScreen: FC = () => {
  const dispatch = useAppDispatch();
  const userDoc = useUserDoc();
  const currentOrgId = userDoc?.currentOrganization;

  useEffect(() => {
    if (!currentOrgId) return;

    const sub = dispatch(subscribeResidencesToFirestore(currentOrgId));
    return () => sub.unsubscribe();
  }, [currentOrgId, dispatch]);

  useAssigneeSubscribe();

  const renderMap = useRenderMemoizedComponent(SubjectMap.Residences);
  const renderList = useRenderMemoizedComponent(ResidenceListNavigation);

  return <MapScreen renderList={renderList} renderMap={renderMap} />;
};

// TODO: Unsure how I feel about this.
export default {
  Business: BusinessMapScreen,
  Residence: ResidenceMapScreen,
};
