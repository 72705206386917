import { StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { PaperSelect } from "react-native-paper-select";
import {
  PaperSelectProps
} from "react-native-paper-select/lib/typescript/interface/paperSelect.interface";

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 20,
  },
  dialog: {
    marginLeft: "auto",
    marginRight: "auto",
    marginStart: "auto",
    marginEnd: "auto",
    width: 300,
  },
  textInput: {
    backgroundColor: "transparent",
  },
});

const StyledPaperSelect = (props: PaperSelectProps) => {
  const theme = useTheme();

  return (
    <PaperSelect
      {...props}
      dialogStyle={{
        ...styles.dialog,
        backgroundColor: theme.colors.background,
      }}
      containerStyle={styles.container}
      textInputStyle={styles.textInput}
      textInputProps={{
        outlineColor: theme.colors.secondary,
        underlineColor: theme.colors.secondary,
      }}
      checkboxProps={{
        checkboxColor: theme.colors.secondary,
        checkboxUncheckedColor: theme.colors.secondary,
        checkboxLabelStyle: {
          color: theme.colors.secondary,
        },
      }}
    />
  );
};

export default StyledPaperSelect;
