import { collection, doc, where, updateDoc } from "firebase/firestore";
import { Observable } from "zen-observable-ts";

import { db } from "../firebase";
import { Organization } from "../../types";

import { subscribeCollection, subscribeDocument } from "./helpers";
import { subscribeUserDoc } from "./users";

export const subscribeCurrentOrganization = (uid: string) => {
  return subscribeUserDoc(uid).flatMap((userDoc) => {
    const orgId = userDoc.currentOrganization;
    if (!orgId) {
      return Observable.of(null);
    }

    return subscribeDocument<Organization>(doc(db, `organizations/${orgId}`));
  });
};

export const subscribeOrganization = (orgId: string) => {
  return subscribeDocument<Organization>(doc(db, `organizations/${orgId}`));
};

export const subscribeOrganizations = (uid: string) => {
  return subscribeCollection<Organization>(
    collection(db, "organizations"),
    where("memberUids", "array-contains", uid)
  );
};

export const updateCurrentOrganization = (uid: string, orgId: string) => {
  const userRef = doc(db, `users/${uid}`);
  return updateDoc(userRef, { currentOrganization: orgId });
};
