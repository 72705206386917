import { Subject } from "../lib/types/subject";
import { useAppSelector } from "../redux/hooks";
import { selectBusinessWithId } from "../redux/businessListSlice";
import { selectResidenceWithId } from "../redux/residenceListSlice";
import { RootState } from "../redux/store";

import useAppRoute from "./useAppRoute";

type SubjectSelector<T extends Subject> =
  (state: RootState, id: string) => T | null;

// makeUseSubjectFromRoute creates a React Hook that will find the "id" param
// in the current route, and use the provided Selector to get the current
// subject from Redux.
const makeUseSubjectFromRoute =
  <T extends Subject>
  (selector: SubjectSelector<T>) =>
    () => {
      const route = useAppRoute();

      const id = route?.params?.id || null;
      if (!id) return null;

      return useAppSelector(
        state => selector(state, id),
      );
    };

export const useBusinessFromRoute =
  makeUseSubjectFromRoute(selectBusinessWithId);

export const useResidenceFromRoute =
  makeUseSubjectFromRoute(selectResidenceWithId);

export default useBusinessFromRoute;
