export type Assignee = {
  id: string;
  uid?: string;
  displayName?: string;
  email?: string;
};

export type Assignees = Assignee[];

export const ASSIGNEE_UNASSIGNED = "Unassigned";
export const ASSIGNEE_UNKNOWN = "Unknown";
