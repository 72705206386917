import { useEffect, useRef } from "react";
import { View } from "react-native";
import { ActivityIndicator, Text } from "react-native-paper";

import Constants from "expo-constants";

import { Clusterer } from "@react-google-maps/marker-clusterer";
import {
  GoogleMap,
  useJsApiLoader,
  Libraries as GoogleMapLibraries,
  Marker,
  MarkerClustererF,
} from "@react-google-maps/api";

import { useAppTheme } from "../../../theme";
import useThemeName from "../../../hooks/useThemeName";
import { IsomorphicMapProps } from "./types";
import { useControllerContext } from "../MapControllerProvider";

import { Mappable } from "./types";
import useUserLocation from "./useUserLocation";
import darkMapStyle from "./map-styles/dark.json";
import lightMapStyle from "./map-styles/light.json";

import IsomorphicMarker from "./IsomorphicMarker";

const { googleMapsApiKeyWeb } = Constants.expoConfig?.extra ?? {};

const libraries: GoogleMapLibraries = ["places"];

const getDefaultPinColor = () => "";

const getUserLocationIcon = () => ({
  path: "M 25, 50 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0",
  fillColor: "#0071BC",
  fillOpacity: 1,
  strokeWeight: 1,
  strokeColor: "white",
  rotation: 0,
  scale: 0.25,
  anchor: new google.maps.Point(25, 25),
});

export default function IsomorphicWebMap<T extends Mappable>({
  onMarkerPress,
  onMapPress,
  onUserLocationLoaded,
  items,
  selected,
  getPinColorForItem = getDefaultPinColor,
}: IsomorphicMapProps<T>) {
  const theme = useAppTheme();
  const themeName = useThemeName();
  const userLocation = useUserLocation();
  const { location } = userLocation;

  const { props: googleMapProps } = useControllerContext();

  // https://github.com/JustFly1984/react-google-maps-api/issues/2849
  const clustererRef = useRef<Clusterer>();
  useEffect(() => {
    clustererRef.current?.repaint();
  }, [items.length, selected]);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKeyWeb,
    libraries,
  });

  useEffect(() => {
    const locationAvailable = location && userLocation.granted;
    if (isLoaded && locationAvailable && onUserLocationLoaded) {
      onUserLocationLoaded({ ...userLocation, location });
    }
  }, [isLoaded, userLocation, location, onUserLocationLoaded]);

  if (loadError) {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <Text>
          There was an issue loading the map. Please reload the page and try again.
        </Text>
      </View>
    );
  }

  if (!isLoaded || userLocation.loading) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: theme.colors.backdrop,
        }}
      >
        <ActivityIndicator size="large" />
      </View>
    );
  }

  return (
    <GoogleMap
      mapContainerStyle={{ width: "100%", height: "100%" }}
      // remove selected if not clicking on marker
      onClick={onMapPress}
      options={{
        disableDefaultUI: true,
        clickableIcons: false,
        styles: themeName === "dark" ? darkMapStyle : lightMapStyle,
      }}
      {...googleMapProps}
    >
      {location && (
        <Marker
          key={"user-location"}
          position={{ lat: location.latitude, lng: location.longitude }}
          icon={getUserLocationIcon()}
          noClustererRedraw={true}
        />
      )}
      <MarkerClustererF
        onLoad={(clusterer) => (clustererRef.current = clusterer)}
        maxZoom={16}
      >
        {(clusterer) => (
          <>
            {items.map((item) => (
              <IsomorphicMarker<T>
                key={item.id}
                item={item}
                color={getPinColorForItem(item)} // FIXME
                clusterer={clusterer}
                onClick={onMarkerPress}
                isSelected={selected?.id === item.id}
              />
            ))}
          </>
        )}
      </MarkerClustererF>
    </GoogleMap>
  );
}
