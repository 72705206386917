import { FC } from "react";
import { ResidenceCategory } from "../../lib/types/residence";
import { useAppTheme } from "../../theme";
import { getResidenceStatusColor } from "../../lib/mapUtils";
import Chip from "./Chip";

interface ResidenceChipProps {
  category: ResidenceCategory;
}

const ResidenceChip: FC<ResidenceChipProps> = ({ category }) => {
  const theme = useAppTheme();
  const backgroundColor = getResidenceStatusColor(theme, category);

  return <Chip text={category} style={{ backgroundColor }} />;
};

export default ResidenceChip;
