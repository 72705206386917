import React, { useCallback } from "react";
import { Linking } from "react-native";
import { Icon } from "react-native-paper";
import { A } from "@expo/html-elements";

import { useAppTheme } from "../../theme";
import ActionItem from "./ActionFlow/ActionItem";

export type GPlacesLinkProps = {
  address: string;
  placeId: string;
}

const makeUrl = (address: string, placeId: string) => {
  return `https://www.google.com/maps/search/?api=1&query=${
    address
  }&query_place_id=${
    placeId
  }`;
};

const GPlacesLink: React.FC<GPlacesLinkProps> = ({
  address,
  placeId,
}) => {
  const theme = useAppTheme();

  return (
    <A
      target="_blank"
      rel="noopener noreferrer"
      href={makeUrl(address, placeId)}
      style={{
        marginTop: 12,
        marginBottom: 12,
        fontSize: 16,
        color: theme.colors.onBackground,
        textDecorationLine: "underline",
      }}
    >
      Open in Google Maps
    </A>
  );
}

export const GPlacesActionItem = ({ address, placeId }: GPlacesLinkProps) => {
  const handlePress = useCallback(() => {
    Linking.openURL(makeUrl(address, placeId));
  }, [address, placeId]);

  return (
    <ActionItem icon="google-maps" onPress={handlePress}>
      Google Maps <Icon source="open-in-new" size={12} />
    </ActionItem>
  );
};

export default GPlacesLink;
