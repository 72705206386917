import { useCallback } from "react";
import { IconButton } from "react-native-paper";

import { useAppTheme } from "../theme";
import useAppNavigation from "../hooks/useAppNavigation";

export default function AddBusinessButton() {
  const theme = useAppTheme();
  const navigation = useAppNavigation();

  const handlePress = useCallback(
    () => navigation.navigate("Add_Business"),
    [navigation],
  );

  return (
    <IconButton
      icon="store-plus"
      mode="contained"
      onPress={handlePress}
      style={{
        backgroundColor: theme.colors.onBackground,
      }}
      iconColor={theme.colors.background}
    />
  );
}
