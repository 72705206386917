import { useEffect, useMemo, useState } from "react";
import * as Location from "expo-location";

import { LatLon } from "./types";

// Request access to the User's location data, and track the progress of the
// request.
const useUserLocation = () => {
  const [loading, setLoading] = useState(true);
  const [granted, setGranted] = useState(false);
  const [location, setLocation] = useState<LatLon | null>(null);

  useEffect(() => {
    (async () => {
      const { status } = await Location.requestForegroundPermissionsAsync();

      if (status === "granted") {
        const loc = await Location.getCurrentPositionAsync({});
        const { latitude, longitude } = loc.coords;

        setLocation({ latitude, longitude });
        setGranted(true);
      }
      setLoading(false);
    })();
  }, []);

  return useMemo(() => ({
    location,
    loading,
    granted,
  }), [location, loading, granted]);
};

export default useUserLocation;
