import { Image as RNImage, ScrollView, View } from "react-native";
import { Button, Card, IconButton, TextInput } from "react-native-paper";
import { useAppTheme } from "../../theme";
import { Control, Controller, useFieldArray } from "react-hook-form";
import useImageSelector from "../shared/ImageSelector";

import { ResidenceInput } from "../../types";

function ResidenceImagePicker({ control }: {
  control: Control<ResidenceInput>;
}) {
  const theme = useAppTheme();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "images",
  });

  const handlePickImage = async () => {
    const imageData = await useImageSelector();
    if (imageData !== null) {
      append (imageData)}
    else{
      console.log("No image selected.")
    }
  }

  function onClosePressed(index: number) {
    remove(index);
  }

  return (
    <View>
      <View
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <Button
          icon="plus-circle"
          onPress={handlePickImage}
          contentStyle={{ flexDirection: "row-reverse" }}
          style={{ borderRadius: 4, marginTop: 4 }}
        >
          Upload Photos
        </Button>
      </View>

      <ScrollView
        showsHorizontalScrollIndicator
        horizontal={true}
        contentContainerStyle={{
          display: "flex",
          flexDirection: "row",
          paddingVertical: 4,
        }}
      >
        {fields.map((field, index) => {
          return (
            <Card
              style={{
                width: 200,
                // padding: 12,
                marginHorizontal: 6,
                marginBottom: 12,
                backgroundColor: theme.colors.background,
                borderColor: theme.colors.backgroundBorder,
                borderWidth: 1,
                borderRadius: 12,
                overflow: "hidden",
              }}
              key={field.id}
            >
              <RNImage
                source={{ uri: field.imageUrl }}
                style={{ height: 130 }}
              />
              {/* <Card.Cover source={{ uri: image }} style={{ height: 130 }} /> */}
              <IconButton
                icon="close"
                mode="contained"
                size={16}
                iconColor={theme.colors.background}
                containerColor={theme.colors.primary}
                style={{ position: "absolute", right: 0 }}
                onPress={() => onClosePressed(index)}
              />
              <Card.Content>
                <Controller
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <TextInput
                      mode="outlined"
                      dense
                      label="Description*"
                      onChangeText={onChange}
                      value={value}
                      outlineStyle={{
                        borderColor: theme.colors.backgroundBorder,
                      }}
                      style={{
                        fontSize: theme.fonts.labelSmall.fontSize,
                        textAlign: "auto",
                        marginTop: 4,
                      }}
                    />
                  )}
                  name={`images.${index}.imageDescription` as const}
                />
              </Card.Content>
            </Card>
          );
        })}
      </ScrollView>
    </View>
  );
}

export default ResidenceImagePicker;
