import partialNotify from "./partialNotify";
import { SnackbarSeverity } from "../redux/snackbarSlice";

type Organization = {
  id: string;
}

type User = {
  id: string;
  displayName?: string;
}

type Subject = {
  id: string;
  name: string;
}

export default function caseCreationErrorNotification(
  organization: Organization,
  subject: Subject,
  user: User
) {
  const errorNotification = partialNotify({ severity: "error" as SnackbarSeverity });

  const emailSubject = encodeURIComponent("Case Creation Error");
  const emailBody = encodeURIComponent(
    [
      "One or more fields have been left blank, preventing Case Creation in Audit Aware.",
      "Please update the missing values in the following Case:",
      `Organization ID: ${organization.id}`,
      `Business ID: ${subject.id}`,
      `Business Name: ${subject.name}`,
      `User ID: ${user.id}`,
      `User Name: ${user.displayName}`,
    ].join("\n")
  );
  const mailtoLink =
    `mailto:help@reason.consulting?subject=${emailSubject}&body=${emailBody}`;

  errorNotification("Error creating case", mailtoLink);
}
