import { IconButton } from "react-native-paper";
import { useNavigation, DrawerActions } from "@react-navigation/native";

import { useAppTheme } from "../theme";

export default function DrawerButton() {
  const theme = useAppTheme();
  const navigation = useNavigation();

  return (
    <IconButton
      icon="menu"
      mode="contained"
      onPress={() => {
        navigation.dispatch(DrawerActions.openDrawer);
      }}
      style={{
        backgroundColor: "transparent",
      }}
      iconColor={theme.colors.onBackground}
    />
  );
}
