import { Keyboard, Platform, TouchableWithoutFeedback, View } from "react-native";
import { Button, IconButton, Text, TextInput, useTheme } from "react-native-paper";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import { useForm, Controller } from "react-hook-form";
import "react-native-get-random-values";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

import { db } from "../Firebase/firebase";
import { PlaceDetails, BusinessAttributes, SubjectStatuses } from "../types";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { v4 as uuidv4 } from "uuid";
import { setSnackbar } from "../redux/snackbarSlice";
import useAppNavigation from "../hooks/useAppNavigation";

interface IFormInput {
  name: string;
  address: string;
  city: string;
  zipCode: string;
  state: string;
  autoComplete: PlaceDetails | undefined;
}

// https://stackoverflow.com/questions/43583367/react-native-google-api-restrictions#:~:text=You%20cannot%20create%20a%20%22restricted,e.g.%20Google%20Places%2C%20Geocode).
const GOOGLE_PLACES_API_KEY = process.env.EXPO_PUBLIC_GOOGLE_PLACES_API_KEY;

export default function AddBusinessForm() {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();

  const { usersLocation } = useAppSelector((state) => state.user);
  const { organization } = useAppSelector((state) => state.organization);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { errors, isDirty, isValid, isSubmitting },
  } = useForm<IFormInput>({
    defaultValues: {
      name: "",
      address: "",
      city: "",
      zipCode: "",
      state: "",
    },
  });

  const addNewBusiness = async (data: IFormInput) => {
    try {
      const uuid = uuidv4();
      const newBusiness: BusinessAttributes = {
        name: data.name,
        address: data.address,
        zipCode: data.zipCode,
        stateAbbr: data.state,
        city: data.city,
        metadata: {
          Bus_Place_ID: getValues("autoComplete.placeId") ?? "",
          Clean_Address: "",
          DC_Source_ID: "",
          DC_Status: "",
          FB_URL: "",
          Parcel_ID: "",
          Unique_ID: "",
          Res_Place_ID: "",
          Score: "",
          Status: "",
          Twitter: "",
        },
        category: "New",
        status: SubjectStatuses.NONE,
        yearBusinessOpened: "",
        createdAt: serverTimestamp(),
        altName: "",
        altNames: [],
        latitude:
          getValues("autoComplete.latitude")?.toString() ??
          usersLocation.latitude.toString(),
        longitude:
          getValues("autoComplete.longitude")?.toString() ??
          usersLocation.longitude.toString(),
        phoneNumber: "",
        phoneNumbers: [],
        website: "",
        websites: [""],
      };

      await setDoc(
        doc(db, `organizations/${organization?.id}/businesses`, uuid),
        newBusiness
      );

      dispatch(
        setSnackbar({
          severity: "success",
          message: "Business added successfully!",
        })
      );

      reset();
      navigation.navigate("Business_List");
    } catch (e) {
      console.error(e);
    }
  };
  const onSubmit = (data: IFormInput) => addNewBusiness(data);

  return (
    <View style={{ marginHorizontal: 30 }}>
      <Text
        style={{
          paddingLeft: 15,
          fontSize: 24,
          fontWeight: "500",
          backgroundColor: theme.colors.background,
        }}
      >
        Add New Business
      </Text>

      {Platform.OS !== "web" ? (
        <View
          style={{
            position: "absolute",
            top: 50,
            elevation: 100,
            zIndex: 100,
            width: "100%",
          }}
        >
          <GooglePlacesAutocomplete
            placeholder="Search Google Autocomplete"
            textInputProps={{
              placeholderTextColor: theme.colors.onBackground,
              returnKeyType: "search",
            }}
            isRowScrollable={false}
            disableScroll={true}
            fetchDetails={true}
            minLength={2}
            listEmptyComponent={() => (
              <View
                style={{
                  paddingLeft: 10,
                  paddingBottom: 10,
                  backgroundColor: theme.colors.background,
                }}
              >
                <Text>No results found</Text>
              </View>
            )}
            renderLeftButton={() => (
              <IconButton icon="google-maps" style={{ padding: 0, margin: 0 }} />
            )}
            GooglePlacesDetailsQuery={{
              fields:
                // eslint-disable-next-line max-len
                "address_components,name,geometry,place_id,website,formatted_phone_number",
            }}
            query={{
              key: GOOGLE_PLACES_API_KEY,
              language: "en",
              components: "country:us",
            }}
            onPress={(data, details = null) => {
              if (details?.name) {
                setValue("name", details?.name);
              }
              let streetAddress = "";
              // eslint-disable-next-line max-len
              for (const component of details?.address_components as google.maps.GeocoderAddressComponent[]) {
                const componentType = component.types[0];

                setValue("autoComplete.latitude", details?.geometry.location.lat ?? 0);
                setValue("autoComplete.longitude", details?.geometry.location.lng ?? 0);
                setValue("autoComplete.placeId", details?.place_id ?? "");

                switch (componentType) {
                  case "street_number": {
                    streetAddress = `${component.long_name}`;
                    setValue("address", `${component.long_name}`, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    break;
                  }

                  case "route": {
                    if (streetAddress != "") {
                      setValue("address", streetAddress + ` ${component.short_name}`, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    } else {
                      setValue("address", `${component.short_name}`, {
                        shouldDirty: true,
                        shouldValidate: true,
                      });
                    }
                    break;
                  }

                  case "postal_code": {
                    setValue("zipCode", `${component.long_name}`, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    break;
                  }

                  case "administrative_area_level_1": {
                    setValue("state", `${component.short_name}`, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                    break;
                  }

                  case "locality":
                    setValue("city", `${component.long_name}`, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });

                    break;

                  case "sublocality_level_1": {
                    setValue("city", `${component.long_name}`, {
                      shouldDirty: true,
                      shouldValidate: true,
                    });
                  }
                }
              }
            }}
            styles={{
              container: {
                flex: 1,
                backgroundColor: theme.colors.background,
                borderWidth: 1,
                borderRadius: 4,
                borderColor: theme.colors.primary,
              },
              textInput: {
                backgroundColor: theme.colors.background,
                color: theme.colors.onBackground,
              },
              row: {
                backgroundColor: theme.colors.background,
              },
              description: {
                color: theme.colors.onBackground,
                backgroundColor: theme.colors.background,
              },
            }}
          />
        </View>
      ) : null}

      <KeyboardAwareScrollView
        contentContainerStyle={{
          paddingTop: Platform.OS == "web" ? 10 : 100,
        }}
      >
        <TouchableWithoutFeedback
          onPress={Keyboard.dismiss}
          disabled={Platform.OS === "web"}
        >
          <>
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  mode="flat"
                  label="Business Name"
                  onChangeText={onChange}
                  value={value}
                  style={{
                    backgroundColor: "transparent",
                    marginBottom: 20,
                  }}
                  error={errors.name ? true : false}
                />
              )}
              name="name"
            />
            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  mode="flat"
                  label="Street Address"
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: "transparent", marginBottom: 20 }}
                  error={errors.address ? true : false}
                />
              )}
              name="address"
            />

            <Controller
              control={control}
              rules={{
                required: true,
              }}
              render={({ field: { onChange, value } }) => (
                <TextInput
                  mode="flat"
                  label="City"
                  onChangeText={onChange}
                  value={value}
                  style={{ backgroundColor: "transparent", marginBottom: 20 }}
                  error={errors.city ? true : false}
                />
              )}
              name="city"
            />

            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Controller
                control={control}
                rules={{
                  required: true,
                  minLength: 2,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    mode="flat"
                    maxLength={2}
                    label="State"
                    onChangeText={(text) => onChange(text.toUpperCase())}
                    value={value}
                    style={{ backgroundColor: "transparent", width: 100 }}
                    error={errors.state ? true : false}
                  />
                )}
                name="state"
              />
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    maxLength={5}
                    keyboardType="numeric"
                    mode="flat"
                    label="Zip"
                    onChangeText={onChange}
                    value={value}
                    style={{ backgroundColor: "transparent", width: 150 }}
                    error={errors.zipCode ? true : false}
                  />
                )}
                name="zipCode"
              />
            </View>

            <Button
              mode="contained"
              loading={isSubmitting}
              disabled={!isDirty || !isValid || isSubmitting}
              color={theme.colors.primary}
              style={{ borderRadius: 4, marginTop: 50 }}
              textColor={"#FFFFFF"}
              onPress={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </>
        </TouchableWithoutFeedback>
      </KeyboardAwareScrollView>
    </View>
  );
}
