import { StyleSheet } from "react-native";

export const stylesheet = StyleSheet.create({
  actionFlow: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    paddingVertical: 24,
  },
  actionItem: {
    alignItems: "center",
    maxWidth: 70,
  },
  actionItemText: {
    textAlign: "center",
  },
});
