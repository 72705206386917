import { StyleSheet } from "react-native";

const caseDetailsStylesheet = StyleSheet.create({
  residenceName: {
    marginBottom: 6,
    fontSize: 22,
    fontWeight: "bold",
  },
  header: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 12,
  },
  actions: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingRight: 12,
  },
  actionItem: {
    opacity: 1,
  },
  terminal: {
    opacity: 0.5,
  },
});

export default caseDetailsStylesheet;
