import { StyleSheet } from "react-native";

const cardDetailsStyles = StyleSheet.create({
  cardInner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
    position: "relative",
  },
  avatar: {
    backgroundColor: "transparent",
  },
  businessName: {
    fontWeight: "bold",
    maxWidth: "65%",
    marginBottom: 6,
  },
  idLine: {
    marginTop: 6,
  },
  residenceName: {
    fontWeight: "bold",
    marginBottom: 6,
  },
});

export default cardDetailsStyles;
