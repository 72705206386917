import {
  ComponentProps,
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { GoogleMap } from "@react-google-maps/api";
import NativeMap, { LatLng, Region } from "react-native-maps";

export type Map = GoogleMap | NativeMap;

export type RegionDelta = {
  latitudeDelta: number;
  longitudeDelta: number;
};

// The Type of the underlying Imperative API is not the same as the Component
// type for Web. ImperativeMap returns the correct underlying type for any
// "Map" type.
type ImperativeMap<T extends Map> = T extends GoogleMap ? google.maps.Map : NativeMap;

export type MapController<T extends Map> = {
  animateTo: (coords: LatLng) => void;
  setRegion: (region: Region) => void;
  map: ImperativeMap<T> | null;
  setMap: Dispatch<SetStateAction<ImperativeMap<T> | null>>;
  getDelta: () => RegionDelta | null;
};

export type UseControllerReturn<T extends Map> = {
  controller: MapController<T>;
  props: T extends GoogleMap
    ? Partial<ComponentProps<typeof GoogleMap>>
    : Partial<ComponentProps<typeof NativeMap>>;
};

export const noopController: MapController<NativeMap> = {
  animateTo() {
    return;
  },
  setRegion() {
    return;
  },
  map: null,
  setMap() {
    return;
  },
  getDelta() {
    return null;
  },
};

const initialDelta = {
  latitudeDelta: 0.1,
  longitudeDelta: 0.1,
};

export const useWebMapController: () => UseControllerReturn<GoogleMap> = () => {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const animateTo = useCallback(
    (coords: LatLng) => {
      if (!map) return;

      map.panTo({
        lat: coords.latitude,
        lng: coords.longitude,
      });
    },
    [map]
  );

  const setRegion: (r: Region) => void = useCallback(() => {
    return;
  }, []);

  const props: Partial<ComponentProps<typeof GoogleMap>> = useMemo(
    () => ({
      onLoad: (m: google.maps.Map) => {
        m.setZoom(10);
        setMap(m);
      },
      onUnmount: () => setMap(null),
    }),
    [setMap]
  );

  // getDelta is a noop on Web.
  const getDelta = useCallback(() => null, []);

  const controller = useMemo(
    () => ({
      map,
      animateTo,
      setMap,
      setRegion,
      getDelta,
    }),
    [map, animateTo, setMap, setRegion, getDelta]
  );

  return {
    controller,
    props,
  };
};

export const useNativeMapController: () => UseControllerReturn<NativeMap> = () => {
  const [map, setMap] = useState<NativeMap | null>(null);

  // Use a ref for the current delta so that we don't have to continually
  // recreate the callbacks when the user pans the map.
  const deltaRef = useRef<RegionDelta>(initialDelta);

  const animateTo = useCallback(
    (coords: LatLng) => {
      if (!map) return;
      map.animateToRegion({ ...deltaRef.current, ...coords });
    },
    [map]
  );

  const setRegion = useCallback((r: Region) => {
    deltaRef.current = {
      latitudeDelta: r.latitudeDelta,
      longitudeDelta: r.longitudeDelta,
    };
  }, []);

  const props: Partial<ComponentProps<typeof NativeMap>> = useMemo(() => {
    return {
      mapRef: setMap,
      onRegionChangeComplete: setRegion,
    };
  }, [setMap, setRegion]);

  const getDelta = useCallback(() => {
    return deltaRef.current;
  }, []);

  const controller = useMemo(
    () => ({
      map,
      animateTo,
      setMap,
      setRegion,
      getDelta,
    }),
    [map, animateTo, setMap, setRegion, getDelta]
  );

  return {
    controller,
    props,
  };
};
