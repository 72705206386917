import { ComponentProps } from "react";
import { StyleSheet } from "react-native";
import { Text } from "react-native-paper";

import { Address } from "../../lib/types/address";

export type AddressProps<T extends Address> = {
  address: T;
  style?: ComponentProps<typeof Text>["style"];
}

const stylesheet = StyleSheet.create({
  text: {
    fontWeight: "700",
    fontSize: 16,
  },
  view: {
    flexDirection: "row",
  }
});

export default function FormattedAddress<T extends Address>({
  address,
  style = {},
}: AddressProps<T>) {

  return (
    <Text style={[stylesheet.text, style]} selectable >
      {address.address}{"\n"}
      {address.city},{" "}
      {address.stateAbbr}{" "}
      {address.zipCode}
    </Text>
  );
}
