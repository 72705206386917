import { useEffect, useMemo, useState, FC } from "react";
import { DataTable } from "react-native-paper";

type PaginationProps = {
  allPagedObjects: string[];
  setCurrentPageObjects: (assignees: string[]) => void;
};

const numberOfItemsPerPageList = [10, 15];
const defaultNumberOfItemsPerPage = numberOfItemsPerPageList[0];

const SnapshotSummaryReportPagination: FC<PaginationProps> = ({
  allPagedObjects,
  setCurrentPageObjects,
}) => {
  const [page, setPage] = useState(0);
  const [numberOfItemsPerPage, setNumberItemPerPage] = useState(
    defaultNumberOfItemsPerPage
  );
  const indexOfFirstItem = useMemo(
    () => page * numberOfItemsPerPage,
    [page, numberOfItemsPerPage]
  );
  const indexOfLastItem = useMemo(
    () => Math.min(indexOfFirstItem + numberOfItemsPerPage, allPagedObjects.length),
    [indexOfFirstItem, numberOfItemsPerPage, allPagedObjects]
  );
  const numberOfPages = useMemo(
    () => Math.ceil(allPagedObjects.length / numberOfItemsPerPage),
    [allPagedObjects, numberOfItemsPerPage]
  );

  useEffect(() => {
    setCurrentPageObjects(allPagedObjects.slice(indexOfFirstItem, indexOfLastItem));
  }, [indexOfFirstItem, indexOfLastItem, allPagedObjects, setCurrentPageObjects]);

  const paginationProps = useMemo(
    () => ({
      page,
      numberOfPages,
      onPageChange: setPage,
      label: `${indexOfFirstItem}-${indexOfLastItem} of ${allPagedObjects.length}`,
      showFastPaginationControls: true,
      numberOfItemsPerPageList,
      numberOfItemsPerPage,
      onItemsPerPageChange: setNumberItemPerPage,
      selectPageDropdownLabel: "Rows per page",
    }),
    [
      page,
      numberOfPages,
      setPage,
      indexOfFirstItem,
      indexOfLastItem,
      allPagedObjects,
      numberOfItemsPerPage,
      setNumberItemPerPage,
    ]
  );

  return <DataTable.Pagination {...paginationProps} />;
};

export default SnapshotSummaryReportPagination;
