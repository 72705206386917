import { useCallback, useMemo } from "react";
import { Linking, View } from "react-native";
import {
  IconButton,
  Snackbar as PaperSnackbar,
  Text,
} from "react-native-paper";

import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { closeSnackbar } from "../redux/snackbarSlice";
import { useAppTheme } from "../theme";

export default function Snackbar() {
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const { isOpen, message, severity, duration, href } = useAppSelector(
    (state) => state.snackbar
  );

  const backgroundColor = useMemo(() => {
    if (severity === "error") {
      return theme.colors.snackbarError;
    } else if (severity === "success") {
      return theme.colors.snackbarSuccess;
    }
    // warning and info
    return theme.colors.snackbarError;
  }, [severity, theme.colors]);

  const onActionPress = useCallback(() => {
    if (href) Linking.openURL(href);
  }, [href]);

  return (
    <PaperSnackbar
      visible={isOpen}
      duration={duration}
      action={href ? {
        label: "View",
        onPress: onActionPress,
        textColor: "white",
      } : undefined}
      icon={href ? "open-in-new" : undefined}
      iconAccessibilityLabel={href ? "Open" : undefined}
      onDismiss={() => {
        dispatch(closeSnackbar());
      }}
      style={{
        backgroundColor,
        justifyContent: "center",
        maxHeight: 60,
        minWidth: 350,
        maxWidth: 500,
        alignItems: "center",
      }}
      wrapperStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {severity === "error" ? (
          <IconButton icon="alert-circle" iconColor="white" />
        ) : (
          <IconButton icon="check" iconColor="white" />
        )}

        <Text style={{ color: "white", fontSize: 16 }}>{message}</Text>
      </View>
    </PaperSnackbar>
  );
}
