// / NOTE: This file has been abstracted to shared/FilterButton.tsx for use with
// Residence Insights as well. This file should be updated to use that, and
// likely moved to a BusinessDetails/ directory to reflect the new
// organization used by ResidenceDetails/.

import { useCallback, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Checkbox, IconButton, Menu, Text } from "react-native-paper";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAppTheme } from "../../theme";
import {
  selectFilterAssignees,
  setFilterAssignees
} from "../../redux/assigneeListSlice";
import useAssigneeSelections from "../../hooks/useAssigneeSelections";

export default function AssigneeFilterButton() {
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const assignees = useAssigneeSelections();
  const selectedAssignees = useAppSelector(selectFilterAssignees);

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleAssigneeFilterChange = useCallback((assigneeId: string) => {
    dispatch(setFilterAssignees(assigneeId));
  }, [dispatch]);

  return (
    <Menu
      visible={isOpen}
      onDismiss={toggleIsOpen}
      anchor={
        <IconButton
          icon="account"
          iconColor={theme.colors.onBackground}
          mode="contained"
          containerColor={theme.colors.background}
          accessibilityLabel="filter"
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderColor: theme.colors.backgroundBorder,
            height: 40,
          }}
          onPress={toggleIsOpen}
        />
      }
    >
      <Text style={{ fontSize: 16, paddingLeft: 10 }}>Filter by Assignee:</Text>
      {assignees.map(({ _id: id, value: displayName }) => (
        <TouchableOpacity
          key={id}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 4,
          }}
          onPress={() => handleAssigneeFilterChange(id)}
        >
          <Checkbox.Android
            status={selectedAssignees.includes(id) ? "checked" : "unchecked"}
            color="#3988BD"
          />
          <Text
            variant="bodyLarge"
            style={{ marginRight: 8 }}
          >
            {displayName}
          </Text>
        </TouchableOpacity>
      ))}
    </Menu>
  );
}
