import { useCallback, useState } from "react";
import { Chip, IconButton, Portal, Snackbar } from "react-native-paper";

import { useAppTheme } from "../../theme";

type HiddenWarningProps = {
  hasHidden: boolean;
}

const message =
  "Some businesses are not shown on the map, please refine your search to see more.";

const HiddenWarning = ({ hasHidden }: HiddenWarningProps) => {
  const [showSnack, setShowSnack] = useState(false);
  const theme = useAppTheme();

  const handlePress = useCallback(() => {
    setShowSnack(true);
  }, []);

  const handleDismiss = useCallback(() => {
    setShowSnack(false);
  }, []);

  return (
    <>
      {hasHidden && (
        <IconButton
          icon="alert"
          size={30}
          onPress={handlePress}
          iconColor={theme.colors.error}
        />
      )}
      <Portal>
        <Snackbar
          visible={showSnack}
          onDismiss={handleDismiss}
          action={{
            label: "Dismiss",
            onPress: handleDismiss,
          }}
        >
          {message}
        </Snackbar>
      </Portal>
    </>
  );
};

export const HiddenWarningBanner = ({ hasHidden }: HiddenWarningProps) => {
  if (!hasHidden) {
    return null;
  }

  return (
    <Chip icon="alert" style={{ display: "flex", alignItems: "center" }}>
      {message}
    </Chip>
  );
};

export default HiddenWarning;
