import { StyleSheet } from "react-native";

const chipWrapperStyle = StyleSheet.create({
  chipWrapper: {
    right: 10,
    left: 0,
    top: 0,
    marginBottom: 5,
    alignSelf: "flex-start",
  },
});

export default chipWrapperStyle;