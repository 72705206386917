import useAppNavigation from "./useAppNavigation";

const useApplicationModeName = () => {
  const navigation = useAppNavigation();
  const state = navigation.getState();
  const root = state.routes[0];
  const history = root.state?.history;
  if (!history || history.length === 0) {
    console.error("No history found in root state");
    return;
  }

  const historyLength = history.length;
  const currentRoute = (
    historyLength === 1 ? history[0] : history[history.length - 2]
  ) as Record<string, string> | undefined;

  if (!currentRoute) {
    console.error("No current route found");
    console.error("History:", history);
    return;
  }

  const [name] = String(currentRoute.key).split("-");

  return name;
};

export default useApplicationModeName;
