import React from "react";

import DisplayInfo from "./DisplayInfo";
import DisplayLabel from "./DisplayLabel";

export type DisplayIfPresentProps = {
  text: string | null | undefined;
  label: string;
  selectable?: boolean;
}

const DisplayIfPresent: React.FC<DisplayIfPresentProps> = ({
  text,
  label,
  selectable = false,
}) => {
  if (!text) return null;

  return (
    <>
      <DisplayLabel text={label} />
      <DisplayInfo text={text} selectable={selectable} />
    </>
  );
}

export default DisplayIfPresent;
