import { orderBy, collection, where, Timestamp, doc } from "firebase/firestore";

import { db } from "../firebase";

import { subscribeCollection, subscribeDocument } from "./helpers";
import { Residence } from "../../lib/types/residence";

import { subscribeOrganization } from "./organizations";

export type Epoch = {
  id: string;
  createdAt: Timestamp;
};

export const subscribeResidences = (orgId: string) => {
  return subscribeOrganization(orgId).flatMap((org) => {
    const epochId = org.residencesEpochId;

    if (!epochId) {
      throw new Error(`Organization ${orgId} does not have residenceEpochId`);
    }

    return subscribeCollection<Residence>(
      collection(db, `organizations/${orgId}/residence_epochs/${epochId}/residences`),
      orderBy("status"),
    );
  });
};

export const subscribeResidence = (orgId: string, residenceId: string) => {
  return subscribeOrganization(orgId).flatMap((org) => {
    const epochId = org.residencesEpochId;

    if (!epochId) {
      throw new Error(`Organization ${orgId} does not have residencesEpochId`);
    }

    return subscribeDocument<Residence>(doc(
      db,
      `organizations/${orgId}/residence_epochs/${epochId}/residences/${residenceId}`,
    ));
  });
};

export const subscribeResidenceGroup = (
  orgId: string,
  epochId: string,
  groupId: string
) => {
  return subscribeCollection<Residence>(
    collection(db, `organizations/${orgId}/residence_epochs/${epochId}/residences`),
    where("groupId", "==", groupId),
  );
};
