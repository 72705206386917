import { useCallback } from "react";
import { View, Platform } from "react-native";
import { Modal, IconButton } from "react-native-paper";

import GoBackButton from "../goBackButton";
import { useAppTheme } from "../../theme";
import useAppNavigation from "../../hooks/useAppNavigation";

export type PordalProps = {
  visible?: boolean;
  onDismiss?: () => void;
  children: React.ReactNode;
};

// Pordal renders it's children inside a Portaled Modal on web clients. On
// native clients, it will use a full screen View without portaling instead.
//
// To get true modal like behavior, you should use this in conjunction with
// the `transparentModal` presentation option on a Stack Navigator, so that
// the previous screen remains mounted behind the modal.
const Pordal: React.FC<PordalProps> = ({
  visible = true,
  children,
  onDismiss,
}) => {
  const theme = useAppTheme();
  const navigation = useAppNavigation();
  const defaultDismissHandler = useCallback(
    () => navigation.goBack(),
    [navigation],
  );

  if (Platform.OS !== "web") {
    return (
      <View style={{ backgroundColor: theme.colors.background, flex: 1 }}>
        <GoBackButton />
        {children}
      </View>
    );
  }

  // NOTE: This _used_ to wrap Modal in a <Portal> from React Native Paper,
  // but <Portal> does not play well with React Contexts. Portal doesn't seem
  // to actually be necessary, so I am disabling it and just using Modal.
  return (
    <Modal
      visible={visible}
      onDismiss={onDismiss || defaultDismissHandler}
      contentContainerStyle={{
        backgroundColor: theme.colors.background,
        padding: 40,
        minWidth: 600,
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={{
        backgroundColor: theme.colors.background,
        flex: 1,
      }}>
        <IconButton
          icon="close"
          onPress={onDismiss || defaultDismissHandler}
          style={{ marginLeft: "auto" }}
        />
        {children}
      </View>
    </Modal>
  );
};

export default Pordal;
