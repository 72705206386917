import React from "react";

import AddBusinessButton from "../addBusinessButton";
import { useAppSelector } from "../../redux/hooks";
import {
  selectSelectedBusiness,
  selectToDoBusinesses,
} from "../../redux/businessListSlice";

import CommonMap from "./CommonMap";

const BusinessesMap: React.FC = () => {
  const business = useAppSelector(selectSelectedBusiness);
  const businesses = useAppSelector(selectToDoBusinesses);

  return (
    <CommonMap
      subjectType="Business"
      selected={business}
      subjects={businesses}
      contextType="business"
      AddSubjectComponent={AddBusinessButton}
    />
  );
};

export default BusinessesMap;
