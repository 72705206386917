import { doc } from "firebase/firestore";
import { Observable } from "zen-observable-ts";

import { db } from "../firebase";
import { User } from "../../types";

import { subscribeDocument } from "./helpers";

type UserDoc = User & {
  currentOrganization: string;
};

export const subscribeUserDoc = (uid: string) => {
  return subscribeDocument<UserDoc>(doc(db, `users/${uid}`)).map((userDoc) => {
    return { ...userDoc, id: uid } as User;
  });
};

export const subscribeCurrentMemberDoc = (uid: string) => {
  return subscribeUserDoc(uid).flatMap((userDoc) => {
    const orgId = userDoc.currentOrganization;

    if (!orgId) {
      return Observable.of(null);
    }
    return subscribeDocument<User>(doc(db, `organizations/${orgId}/members/${uid}`)).map(
      (memberDoc) => ({
        ...userDoc,
        ...memberDoc,
      })
    );
  });
};
