import { LinkingOptions } from "@react-navigation/native";

// RootStackParamList is a list of all the screens in the app, and their
// possible parameters.
export type RootStackParamList = {
  Root: undefined;
  Login: undefined;
  Forgot_Password: undefined;
  Businesses: undefined;
  BI_Map: undefined;
  RI_Map: undefined;
  Details: undefined;
  Edit: undefined;
  Home: undefined;
  Add_Business: undefined;
  Edit_Business: { id: string };
  Business_Detail: { id: string };
  Business_List: undefined;
  Business_ToDo: undefined;
  Business_Done: undefined;
  Residences: undefined;
  Edit_Residence: { id: string };
  Residence_Detail: { id: string };
  Residence_List: undefined;
  Residence_ToDo: undefined;
  Residence_Done: undefined;
};

// Linking defines the URL structure of your appplication, and needs to
// reflect the hierarchy of Navigators in your app. The keys in `screens`
// objects are unique Screen names. The values are either the URL path for the
// Screen, or an object defining sub-screens.
export const appLinkingOptions: LinkingOptions<RootStackParamList> = {
  prefixes: ["https://canvas.auditaware.app"],
  config: {
    screens: {
      Root: {
        path: "",
        screens: {
          Businesses: {
            path: "businesses",
            initialRouteName: "BI_Map",
            screens: {
              BI_Map: {
                path: "",
                screens: {
                  Business_List: "",
                  Business_ToDo: "to-do",
                  Business_Done: "done",
                  Business_Detail: ":id",
                },
              },
              Add_Business: "new",
              Edit_Business: ":id/edit",
            },
          },
          Residences: {
            path: "residences",
            screens: {
              RI_Map: {
                path: "",
                screens: {
                  Residence_List: "",
                  Residence_ToDo: "to-do",
                  Residence_Done: "done",
                  Residence_Detail: ":id",
                },
              },
              Edit_Residence: ":id/edit",
            },
          },
        },
      },
      Login: "login",
      Forgot_Password: "forgot-password",
    },
  },
};
