import React, { useState, useEffect, createContext } from "react";
import { useColorScheme, View } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { darkTheme, lightTheme } from "../theme";
import { PaperProvider } from "react-native-paper";

export enum ThemeModeEnum {
  SYSTEM = "system",
  LIGHT = "light",
  DARK = "dark",
}

type ThemeModeProviderProps = {
  children?: React.ReactNode;
};

type ThemeContextArgs = [
  ThemeModeEnum,
  React.Dispatch<React.SetStateAction<ThemeModeEnum>>,
];

const storageThemeModeKey = "dc/theme-mode";

export const ThemeModeContext = createContext<ThemeContextArgs>([
  ThemeModeEnum.SYSTEM,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (mode: React.SetStateAction<ThemeModeEnum>) => undefined,
]);

export const getThemeName = (mode: ThemeModeEnum, colorScheme: string) => {
  const themeName = mode === ThemeModeEnum.SYSTEM ? colorScheme : mode;
  return themeName === "light" ? ThemeModeEnum.LIGHT : ThemeModeEnum.DARK;
};

const AppThemeProvider: React.FC<ThemeModeProviderProps> = ({ children }) => {
  const [mode, setMode] = useState(ThemeModeEnum.SYSTEM);
  const colorScheme = useColorScheme() || "light";
  const themeName = getThemeName(mode, colorScheme);
  const theme = themeName === ThemeModeEnum.LIGHT ? lightTheme : darkTheme;

  useEffect(() => {
    // Read the value from AsyncStorage on start.
    AsyncStorage.getItem(storageThemeModeKey).then((value) => {
      const m = (Object.values(ThemeModeEnum) as unknown[]).includes(value)
        ? (value as ThemeModeEnum)
        : null;

      if (m) setMode(m);
    });
  }, [setMode]);

  useEffect(() => {
    // Save the value to AsyncStorage on change.
    AsyncStorage.setItem(storageThemeModeKey, mode);
  }, [mode]);

  return (
    <View style={{ overflow: "hidden", flex: 1 }}>
      <ThemeModeContext.Provider value={[mode, setMode]}>
        <PaperProvider theme={theme}>{children}</PaperProvider>
      </ThemeModeContext.Provider>
    </View>

  );
};

export default AppThemeProvider;
