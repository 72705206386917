import { useTheme } from "react-native-paper";
import { Controller, Control, Path } from "react-hook-form";
import {
  SelectedItem
} from "react-native-paper-select/lib/typescript/interface/paperSelect.interface";
import StyledPaperSelect from "../shared/StyledPaperSelect";
import useAssigneeSelections from "../../hooks/useAssigneeSelections";
import { SubjectInput } from "../../types";

interface AssigneeSelectProps<T extends SubjectInput> {
  control: Control<T, T>
}

const AssigneeSelect = <T extends SubjectInput,>({
  control,
}: AssigneeSelectProps<T>) => {
  const theme = useTheme();
  const assigneeSelections = useAssigneeSelections();

  return (
    <Controller
      control={control}
      name={"assigneeId" as Path<T>}
      render={({ field: { onChange, value } }) => (
        <StyledPaperSelect
          label="Assignees"
          value={
            assigneeSelections.find((assignee) => {
              return assignee._id === (value ? value : "");
            })?.value || ""
          }
          onSelection={(v: SelectedItem) => {
            onChange(v.selectedList[0]?._id || "");
          }}
          arrayList={assigneeSelections}
          multiEnable={false}
          hideSearchBox={true}
          theme={theme}
          dialogCloseButtonText="Cancel"
          selectedArrayList={assigneeSelections.filter((i) => i._id === value)}
        />
      )}
    />
  );
};

export default AssigneeSelect;
