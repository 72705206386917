import { useCallback } from "react";
import { Card } from "react-native-paper";
import { BottomSheetVirtualizedList } from "@gorhom/bottom-sheet";

import {
  ActivityIndicator,
  ListRenderItem,
  Platform,
  StyleSheet,
  VirtualizedList,
} from "react-native";

import { useAppTheme } from "../../theme";
import { Subject } from "../../lib/types/subject";
import useAppNavigation from "../../hooks/useAppNavigation";
import useAppBottomSheet from "../../hooks/useAppBottomSheet";
import NoData from "../shared/NoData";

type CommonListProps<T extends Subject> = {
  subjectType: "Residence" | "Business";
  isLoading: boolean;
  subjects: T[];
  renderSubject: (subject: T) => React.ReactNode;
  children?: (subject: T) => React.ReactNode;
};

type CardContainerProps<T extends Subject> = {
  subjectType: "Residence" | "Business";
  subject: T;
  render: (subject: T) => React.ReactNode;
};

const isWeb = Platform.OS === "web";
const PlatformList = isWeb ? VirtualizedList : BottomSheetVirtualizedList;

const keyExtractor = (subject: Subject) => subject.id;
const getItemCount = (data: Subject[]) => data.length;
const getItem = <T extends Subject>(data: T[], index: number) => data[index];

const stylesheet = StyleSheet.create({
  card: {
    padding: 12,
    borderBottomWidth: 1,
    borderRadius: 0,
  },
});

const CardContainer = <T extends Subject>({
  subjectType,
  render,
  subject,
}: CardContainerProps<T>) => {
  const { background: backgroundColor, backgroundBorder: borderColor } =
    useAppTheme().colors;

  const navigation = useAppNavigation();
  const bottomSheet = useAppBottomSheet();

  const handlePress = useCallback(() => {
    navigation.navigate(`${subjectType}_Detail`, { id: subject.id });
    bottomSheet?.snapToIndex(2);
  }, [subject, subjectType, navigation, bottomSheet]);

  return (
    <Card
      mode="contained"
      style={[
        stylesheet.card,
        {
          backgroundColor,
          borderColor,
        },
      ]}
      onPress={handlePress}
    >
      {render(subject)}
    </Card>
  );
};

export default function CommonList<T extends Subject>({
  subjectType,
  isLoading,
  subjects,
  renderSubject,
}: CommonListProps<T>) {
  const renderCard: ListRenderItem<T> = useCallback(
    ({ item }) => {
      return (
        <CardContainer<T>
          subjectType={subjectType}
          key={item.id}
          subject={item}
          render={renderSubject}
        />
      );
    },
    [subjectType, renderSubject]
  );

  if (subjects.length === 0) {
    return <NoData text="No data currently loaded" />;
  }

  if (isLoading) {
    return <ActivityIndicator size={50} style={{ marginTop: 50 }} />;
  }

  return (
    <PlatformList<T>
      keyExtractor={keyExtractor}
      getItemCount={getItemCount}
      getItem={getItem}
      data={subjects}
      renderItem={renderCard}
    />
  );
}
