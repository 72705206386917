import { useBusinessFromRoute } from "../hooks/useBusinessFromRoute";
import { selectOrganization } from "../redux/organizationSlice";
import { selectUser } from "../redux/userSlice";
import { useAppSelector } from "../redux/hooks";
import Pordal from "../components/shared/Pordal";
import BusinessEditForm
  from "../components/BusinessDetails/BusinessEditForm";

export default function EditScreen() {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const business = useBusinessFromRoute();

  if (!business || !organization || !user) {
    return <></>;
  }

  return (
    <Pordal>
      <BusinessEditForm
        business={business}
        organization={organization}
        user={user}
      />
    </Pordal>
  );
}
