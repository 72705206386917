import { orderBy, onSnapshot, collection, doc, where } from "firebase/firestore";

import { db } from "../firebase";
import { Attachment, Business, Note } from "../../types";
import { Observable } from "zen-observable-ts";

import { subscribeCollection } from "./helpers";

export const subscribeBusinesses = (orgId: string) => {
  return subscribeCollection<Business>(
    collection(db, `organizations/${orgId}/businesses`),
    orderBy("status"),
    orderBy("name")
  );
};

export const subscribeBusinessNotes = (orgId: string, businessId: string) => {
  return subscribeCollection<Note>(
    collection(db, `organizations/${orgId}/businesses/${businessId}/notes`),
    orderBy("createdAt", "desc")
  );
};

export const subscribeAttachments = (orgId: string, businessId: string) => {
  return subscribeCollection<Attachment>(
    collection(db, `organizations/${orgId}/businesses/${businessId}/attachments`),
    orderBy("createdAt", "desc")
  );
};

export const subscribeBusiness = (orgId: string, businessId: string) => {
  const path = `organizations/${orgId}/businesses/${businessId}`;
  const businessRef = doc(db, path);

  return new Observable<Business>((subscriber) => {
    try {
      return onSnapshot(businessRef, (snapshot) => {
        const business = snapshot.data() as Business;
        subscriber.next({ ...business, id: snapshot.id } as Business);
      });
    } catch (err) {
      subscriber.error(err);
    }
  });
};

export const subscribeBusinessGroup = (
  orgId: string,
  groupId: string
) => {
  return subscribeCollection<Business>(
    collection(db, `organizations/${orgId}/businesses`),
    where("metadata.Res_Place_ID", "==", groupId),
  );
};
