import { Assignee } from "../lib/types/assignee";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";
import { selectAssignees } from "../redux/assigneeListSlice";

export const useAssignee = (assigneeId: string | undefined) => {
  return useSelector<RootState, Assignee | null>((state) => {
    if (!assigneeId) return null;

    const selectedAssignee = selectAssignees(state).find(a => a.id === assigneeId);

    return selectedAssignee || null;
  });
};
