import { ActionItemProps } from "./ActionItem"
import { FC } from "react";
import { View } from "react-native";
import caseDetailsStylesheet from "../../../Styles/caseDetailsStyles";
import ActionFlow from "./index";

type SubjectActionItemProps = ActionItemProps & {
  isTerminal: boolean;
};

const SubjectActionItem: FC<SubjectActionItemProps> = ({
  isTerminal,
  onPress,
  ...props
}) => {
  return (
    <View style={caseDetailsStylesheet[isTerminal ? "terminal" : "actionItem"]}>
      <ActionFlow.Item {...props} onPress={isTerminal ? undefined : onPress} />
    </View>
  );
};

export default SubjectActionItem;
