import { useCallback, useEffect, FC } from "react";
import { StyleSheet, View } from "react-native";
import { Card, IconButton, Text } from "react-native-paper";

import {
  selectColocated,
  setColocated,
  subscribeColocatedToFirestore,
} from "../../redux/businessListSlice";

import { AppNavigation } from "../../hooks/useAppNavigation";
import useAppBottomSheet from "../../hooks/useAppBottomSheet";

import { Organization, Business } from "../../types";

import NoData from "../shared/NoData";
import FormattedAddress from "../shared/FormattedAddress";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import detailStyles from "../DetailStyles";

export type ColocatedProps = {
  appNavigation: AppNavigation;
  organization: Organization;
  business: Business;
};

type ColocatedCardProps = {
  appNavigation: AppNavigation;
  business: Business;
};

const stylesheet = StyleSheet.create({
  relatedBlock: {
    height: "auto",
    paddingBottom: 4,
  },
  relatedCard: {
    marginTop: 12,
  },
  relatedCardTitle: {
    marginBottom: 6,
    fontSize: 16,
    fontWeight: "bold",
  },
  relatedCardAddress: {
    marginBottom: 6,
    fontWeight: "400",
    fontSize: 14,
  },
});

const ColocatedCard: FC<ColocatedCardProps> = ({ business, appNavigation }) => {
  const bottomSheet = useAppBottomSheet();
  const { Unique_ID: uniqueId, Parcel_ID: parcelId } = business.metadata || {};
  const handlePress = useCallback(() => {
    bottomSheet?.snapToIndex(2);
    appNavigation.navigate("Business_Detail", { id: business.id });
  }, [appNavigation, bottomSheet, business.id]);

  return (
    <Card mode="elevated" style={stylesheet.relatedCard} onPress={handlePress}>
      <Card.Content
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{
          flexGrow: 1,
          flex: 1
        }}>
          <Text style={stylesheet.relatedCardTitle}>
            {business.name}
          </Text>
          <FormattedAddress address={business} style={stylesheet.relatedCardAddress} />
          {parcelId &&
            <Text>
              Parcel ID: {parcelId}
            </Text>
          }
          {uniqueId &&
            <Text>
              Unique ID: {uniqueId}
            </Text>
          }
        </View>

        <View style={{ flexGrow: 0 }}>
          <IconButton icon="arrow-right" />
        </View>
      </Card.Content>
    </Card>
  );
};

const ColocatedBusinesses: FC<ColocatedProps> = ({
  organization,
  business,
  appNavigation,
}) => {
  const dispatch = useAppDispatch();
  const colocatedBusinesses = useAppSelector(selectColocated);

  useEffect(() => {
    if (!business.metadata.Res_Place_ID) return () => {};

    const sub = dispatch(
      subscribeColocatedToFirestore(
        organization.id,
        business.metadata.Res_Place_ID
      )
    );

    return () => {
      dispatch(setColocated([]));
      sub.unsubscribe();
    };
  }, [dispatch, organization, business]);

  return (
    <View style={[stylesheet.relatedBlock, detailStyles.detailBlock]}>
      {colocatedBusinesses.length === 0 && <NoData text="No Colocated Businesses" />}

      {colocatedBusinesses.map((colocated) => {
        if (colocated.id === business.id) return null;

        return (
          <ColocatedCard
            key={colocated.id}
            business={colocated}
            appNavigation={appNavigation}
          />
        );
      })}
    </View>
  );
};

export default ColocatedBusinesses;
