import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { User } from "../types";
import { AppDispatch, RootState } from "./store";
import { subscribeCurrentMemberDoc } from "../Firebase/queries/users";
import { notifyAndLogError } from "./snackbarSlice";

interface UsersLocation {
  latitude: number;
  longitude: number;
}

export interface UserState {
  user: User | null;
  usersLocation: UsersLocation;
  loginLoading: boolean;
}

const initialState: UserState = {
  user: null,
  usersLocation: {
    latitude: 37.0902,
    longitude: -95.7129,
  },
  loginLoading: false,
};

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setUsersLocation: (state, action: PayloadAction<UsersLocation>) => {
      const { latitude, longitude } = action.payload;
      state.usersLocation = { latitude, longitude };
    },
    setLoginLoading: (state, action: PayloadAction<boolean>) => {
      state.loginLoading = action.payload;
    },
  },
});

export const { setUser, setUsersLocation, setLoginLoading } = user.actions;

// Thunks

export const subscribeMemberToFirestore =
  (uid: string) =>
    (dispatch: AppDispatch) =>
      subscribeCurrentMemberDoc(uid).subscribe(
        (member: User | null) => dispatch(setUser(member)),
        (error: Error) => dispatch(notifyAndLogError(error))
      );

export const selectUser = (state: RootState) => state.user.user;

export default user.reducer;
