import React from "react";
import { View, ActivityIndicator, StyleSheet, ImageBackground } from "react-native";
import splash from "../assets/splash.png";

export default function Loading() {
  return (
    <ImageBackground
      source={splash}
      resizeMode="cover"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="white" />
      </View>
    </ImageBackground>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    position: "relative",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
