import { TouchableOpacity } from "react-native";
import { IconButton, Text } from "react-native-paper";
import { useAppTheme } from "../theme";
import { useNavigation } from "@react-navigation/native";

type Props = {
  overrideStyle?: boolean;
};

export default function GoBackButton({ overrideStyle }: Props) {
  const theme = useAppTheme();
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        marginTop: 42,
      }}
      onPress={() => navigation.goBack()}
    >
      <IconButton
        icon="arrow-left"
        iconColor={overrideStyle ? "white" : theme.colors.onBackground}
      />
      <Text
        variant="titleMedium"
        style={{
          color: overrideStyle ? "white" : theme.colors.onBackground,
        }}
      >
        Back
      </Text>
    </TouchableOpacity>
  );
}
