import React, { ReactNode, useContext } from "react";
import { Platform } from "react-native";
import { GoogleMap } from "@react-google-maps/api";
import NativeMap from "react-native-maps";

import {
  UseControllerReturn,
  noopController,
  useNativeMapController,
  useWebMapController,
} from "../../lib/MapAPI";

const ControllerContext = React.createContext<
  UseControllerReturn<GoogleMap | NativeMap> 
>({
  props: {},
  controller: noopController,
});

const MapControllerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const nativeCtx = useNativeMapController();
  const webCtx = useWebMapController();

  if (Platform.OS === "web") {
    return (
      <ControllerContext.Provider value={webCtx}>
        {children}
      </ControllerContext.Provider>
    );
  }

  return (
    <ControllerContext.Provider value={nativeCtx}>
      {children}
    </ControllerContext.Provider>
  );
};

export const useControllerContext = () =>
  useContext(ControllerContext);

export default MapControllerProvider;
