import { Text } from "react-native-paper";
import { View } from "react-native";
import { useAppTheme } from "../../theme";

interface ChipProps {
  text: string;
  style?: React.ComponentProps<typeof View>["style"];
}

export default function Chip({
  text,
  style = {},
}: ChipProps) {
  const theme = useAppTheme();

  return (
    <View
      style={[
        {
          borderRadius: 50,
          paddingRight: 6,
          paddingLeft: 6,
          paddingBottom: 2,
          paddingTop: 2,
          backgroundColor: theme.colors.newMarkerColor,
        },
        style,
      ]}
      testID="chip"
    >
      <Text
        style={{
          color: theme.colors.chipTextColor,
        }}
        variant="labelSmall"
      >
        {text}
      </Text>
    </View>
  );
}
