import AddBusinessForm from "../components/addBusinessForm";
import Pordal from "../components/shared/Pordal";

export default function AddBusinessScreen() {
  return (
    <Pordal>
      <AddBusinessForm/>
    </Pordal>
  );
}
