import {
  View,
  Image,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
  ImageBackground,
  TouchableOpacity,
} from "react-native";
import { HelperText, TextInput, Button, Text } from "react-native-paper";
import React, { useCallback, useState } from "react";
import { getAuth, signInWithEmailAndPassword, UserCredential } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { FirebaseError } from "@firebase/util";

import { db } from "../Firebase/firebase";
import { setSnackbar } from "../redux/snackbarSlice";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { setLoginLoading } from "../redux/userSlice";
import useAppNavigation from "../hooks/useAppNavigation";
import webBackground from "../assets/web_background.png";
import logoDark from "../assets/logo-dark.png";

const syncUserWithFirestore = (creds: UserCredential): Promise<void> => {
  const { user } = creds;
  return setDoc(doc(db, "users", user.uid), { email: user.email }, { merge: true });
};

export default function LoginScreen() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const auth = getAuth();
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();

  const loginLoading = useAppSelector((state) => state.user.loginLoading);

  const handleEmail = useCallback((value: string) => {
    setEmail(value);
    if (value.length > 0 && emailError) {
      setEmailError(false);
    }
  }, [emailError]);

  const handlePassword = useCallback((value: string) => {
    setPassword(value);
    if (value.length > 5) {
      setPasswordError(false);
    }
  }, []);

  const handleLogin = useCallback(async () => {
    if (email.length < 1 && password.length < 6) {
      setEmailError(true);
      setPasswordError(true);
    } else if (password.length < 6) {
      setPasswordError(true);
    } else if (email.length < 1) {
      setEmailError(true);
    } else {
      try {
        dispatch(setLoginLoading(true));
        const creds = await signInWithEmailAndPassword(auth, email, password);
        await syncUserWithFirestore(creds);
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          dispatch(
            setSnackbar({
              severity: "error",
              message: "Incorrect email or password",
            })
          );
        }
      }
      dispatch(setLoginLoading(false));
    }
  }, [auth, dispatch, email, password]);

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <TouchableWithoutFeedback
        onPress={Keyboard.dismiss}
        disabled={Platform.OS === "web"}
      >
        <ImageBackground
          source={webBackground}
          resizeMode="cover"
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <View
            style={{
              width: "90%",
              maxWidth: 500,
              height: "70%",
              maxHeight: 600,
              padding: 20,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Image
              source={logoDark}
              style={{
                width: "100%",
                height: 100,
                resizeMode: "contain",
              }}
            />

            <View style={{ width: "100%", marginTop: 12 }}>
              <TextInput
                mode="flat"
                autoCapitalize="none"
                label="Email"
                blurOnSubmit={false}
                value={email}
                onChangeText={handleEmail}
                error={emailError}
                style={{ backgroundColor: "transparent" }}
                selectionColor="#DE6750"
                textColor="white"
                underlineColor="white"
                activeUnderlineColor="white"
                theme={{
                  colors: {
                    onSurfaceVariant: "white",
                  },
                }}
              />
              <HelperText
                type="error"
                visible={emailError}
                style={{
                  marginBottom: 10,
                  fontSize: 16,
                }}
              >
                Invalid Email Address
              </HelperText>
              <TextInput
                label="Password"
                secureTextEntry={!showPassword}
                right={
                  <TextInput.Icon
                    icon="eye"
                    color="white"
                    onPress={() => setShowPassword(!showPassword)}
                  />
                }
                value={password}
                onChangeText={handlePassword}
                style={{ backgroundColor: "transparent" }}
                error={passwordError}
                selectionColor="#DE6750"
                textColor="white"
                underlineColor="white"
                activeUnderlineColor="white"
                theme={{
                  colors: {
                    onSurfaceVariant: "white",
                  },
                }}
              />
              <HelperText
                type="error"
                visible={passwordError}
                style={{
                  fontSize: 16,
                }}
              >
                Password must be at least 6 characters long
              </HelperText>
              <TouchableOpacity onPress={() => navigation.navigate("Forgot_Password")}>
                <Text
                  style={{
                    fontSize: 13,
                    color: "white",
                    textAlign: "right",
                    marginBottom: 12,
                  }}
                >
                  Forgot Password?
                </Text>
              </TouchableOpacity>
            </View>
            <Button
              mode="elevated"
              icon="send"
              loading={loginLoading}
              disabled={loginLoading}
              onPress={handleLogin}
              textColor={"white"}
              buttonColor={"#00101B"}
              style={{
                borderRadius: 2,
                paddingVertical: 5,
              }}
            >
              {loginLoading ? "" : "Login"}
            </Button>
          </View>
        </ImageBackground>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}
