import { FC, useCallback, useEffect } from "react";
import { IconButton } from "react-native-paper";
import { useAppDispatch } from "../../redux/hooks";
import { setSelectedBusiness } from "../../redux/businessListSlice";
import useBusinessFromRoute from "../../hooks/useBusinessFromRoute";
import useAppNavigation from "../../hooks/useAppNavigation";
import BusinessBackWrapper from "./BusinessCaseWrapper";

const BusinessContent: FC = () => {
  const navigation = useAppNavigation();

  const handleBackPress = useCallback(() => {
    navigation.navigate("Business_List");
  }, [navigation]);

  return (
    <>
      <IconButton
        icon="arrow-left"
        onPress={handleBackPress}
        style={{
          margin: 0,
        }}
      />
      <BusinessBackWrapper />
    </>
  );
};

export default function BusinessDetails() {
  const dispatch = useAppDispatch();
  const business = useBusinessFromRoute();

  useEffect(() => {
    dispatch(setSelectedBusiness(business || null));
    return () => {
      dispatch(setSelectedBusiness(null));
    };
  }, [dispatch, business]);

  if (!business) {
    return <></>;
  }
  return <BusinessContent />;
}
