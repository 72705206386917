// NOTE: The Residence counterpart to this component has been moved to
// components/ResidenceList/. This File should probably receive the save
// treatment.

import { useCallback, useMemo } from "react";
import { View } from "react-native";
import { Avatar, Text } from "react-native-paper";

import { useAppTheme } from "../../theme";
import { useAppSelector } from "../../redux/hooks";
import {
  selectToDoBusinesses,
  selectDoneBusinesses,
  selectLoadingBusinesses,
} from "../../redux/businessListSlice";
import BusinessChip from "../../components/shared/BusinessChip";
import { Business } from "../../lib/types/business";

import CommonList from "./CommonList";
import { useAssignee } from "../../hooks/useAssignee";
import { AssigneeText } from "../shared/AssigneeText";
import { SubjectStatuses } from "../../lib/types/subjectStatuses";
import TerminalSubjectStatusChip from "../shared/TerminalSubjectChip";
import chipWrapperStyle from "../../Styles/chipWrapper";
import cardDetailsStyles from "../../Styles/cardDetailsStyles";

type BusinessCardProps = {
  business: Business;
  terminal?: boolean;
};

const StyledText = (props: { [key: string]: unknown; children: React.ReactNode }) => {
  const theme = useAppTheme();
  const { onBackground } = theme.colors;
  const styles = useMemo(() => ({ color: onBackground }), [onBackground]);

  return <Text {...props} style={styles} />;
};

const BusinessCard: React.FC<BusinessCardProps> = ({ business }) => {
  const theme = useAppTheme();
  const { onBackground } = theme.colors;

  const parcelId = business.metadata?.Parcel_ID || null;
  const uniqueId = business.metadata?.Unique_ID || null;

  const assignee = useAssignee(business.assigneeId);

  const isTerminal =
    business.status === SubjectStatuses.CASE_CREATED ||
    business.status === SubjectStatuses.DISMISSED;

  return (
    <View style={cardDetailsStyles.cardInner}>
      <View style={{ display: "flex", flexDirection: "column" }}>
        <Avatar.Icon icon="store" color={onBackground} style={cardDetailsStyles.avatar} />
      </View>
      <View style={{ flexGrow: 2 }}>
        <Text style={cardDetailsStyles.businessName}>
          {business.name}
        </Text>
        <View style={chipWrapperStyle.chipWrapper}>
          {isTerminal ? (
            <TerminalSubjectStatusChip status={business.status} />
          ) : (
            <BusinessChip category={business.category} />
          )}
        </View>
        <AssigneeText assignee={assignee} />
        <StyledText>{business.address}</StyledText>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <StyledText>{business.city}, </StyledText>
          <StyledText>{business.stateAbbr} </StyledText>
          <StyledText>{business.zipCode}</StyledText>
        </View>
        {parcelId && (
          <View style={cardDetailsStyles.idLine}>
            <StyledText>{parcelId}</StyledText>
          </View>
        )}
        {uniqueId && (
          <View style={cardDetailsStyles.idLine}>
            <StyledText>{uniqueId}</StyledText>
          </View>
        )}
      </View>
    </View>
  );
};

type BusinessListProps = {
  terminal?: boolean;
};

export default function BusinessList({ terminal = false }: BusinessListProps) {
  const isLoading = useAppSelector(selectLoadingBusinesses);
  const filteredBusinesses = useAppSelector(
    terminal ? selectDoneBusinesses : selectToDoBusinesses
  );

  const renderBusiness = useCallback(
    (item: Business) => <BusinessCard key={item.id} business={item} />,
    []
  );

  return (
    <CommonList
      subjectType="Business"
      isLoading={isLoading}
      subjects={filteredBusinesses}
      renderSubject={renderBusiness}
    />
  );
}
