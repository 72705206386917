import { selectToDoBusinesses } from "./businessListSlice";
import { selectToDoResidences } from "./residenceListSlice";
import { selectAssignees } from "./assigneeListSlice";
import { createSelector } from "@reduxjs/toolkit";

import { Subject, SubjectCategory, Assignee } from "../types";

type AssigneeNames = {
  [assigneeId: string]: string;
  unassigned: "Unassigned";
}

type CategorySnapshot = {
  [category in SubjectCategory]?: number;
}

type TotaledCategorySnapshot = CategorySnapshot & {
  total: number;
}

type AssigneeCategorySnapshot = {
  [assigneeId: string]: TotaledCategorySnapshot;
  totals: TotaledCategorySnapshot;
}

type SnapshotReport = {
  categories: SubjectCategory[];
  assigneeNames: { [assigneeId: string]: string };
  assigneeCategories: AssigneeCategorySnapshot;
}

const snapshotSummaryReportSubjectResultFunc = (
  subjects: Subject[],
  assignees: Assignee[]
): SnapshotReport => {
  const categories = [...new Set(subjects.map(subject => subject.category))];

  const initialAssigneeNames: AssigneeNames = { unassigned: "Unassigned" };

  const assigneeNames = assignees.reduce((acc, assignee) => {
    acc[assignee.id] = assignee.displayName || assignee.email || "Unknown";
    return acc;
  }, initialAssigneeNames);

  const initialSnapshot: AssigneeCategorySnapshot = {
    totals: { total: 0 }
  };

  const assigneeCategories = subjects.reduce((acc, subject) => {
    const { category } = subject;
    const assigneeId = subject.assigneeId || "unassigned";

    if (!acc[assigneeId]) {
      acc[assigneeId] = { total: 0 };
    }

    if (!acc.totals[category]) {
      acc.totals[category] = 0;
    }

    if (!acc[assigneeId][category]) {
      acc[assigneeId][category] = 0;
    }

    acc.totals[category] += 1;
    acc.totals.total += 1;
    acc[assigneeId][category] += 1;
    acc[assigneeId].total += 1;

    return acc;
  }, initialSnapshot);

  return {
    categories,
    assigneeNames,
    assigneeCategories,
  };
};

export const selectBusinessSnapshotSummaryReport = createSelector(
  [selectToDoBusinesses, selectAssignees],
  snapshotSummaryReportSubjectResultFunc
);

export const selectResidenceSnapshotSummaryReport = createSelector(
  [selectToDoResidences, selectAssignees],
  snapshotSummaryReportSubjectResultFunc
);
