import { v4 as uuidv4 } from "uuid";
import { collection, doc, orderBy, serverTimestamp, setDoc } from "firebase/firestore";
import { Note } from "../../lib/types/note";
import { db } from "../firebase";
import { subscribeCollection } from "./helpers";

export type CreateNoteArgs = {
  uid: string;
  text: string;
  parentPath: string;
};

export const subscribeNotes = (parentPath: string) => {
  return subscribeCollection<Note>(
    collection(db, `${parentPath}/notes`),
    orderBy("createdAt", "desc")
  );
};

export const createNote = ({ uid, text, parentPath }: CreateNoteArgs) => {
  const noteId = uuidv4();
  const currentDateTime = serverTimestamp();
  const noteData: Note = {
    text,
    createdAt: currentDateTime,
    updatedAt: currentDateTime,
    uuid: noteId,
    createdByUid: uid,
  };

  return setDoc(doc(db, `${parentPath}/notes`, noteId), noteData);
};
