import { useCallback, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Text, IconButton, Menu, Checkbox } from "react-native-paper";

import { SubjectCategory } from "../../lib/types/subject";
import { useAppTheme } from "../../theme";

export type ChangeHandler<T extends SubjectCategory> = (
  status: T,
  selected: T[]
) => unknown;

export type FilterButtonProps<T extends SubjectCategory> = {
  options: Record<string, T>;
  selected: T[];
  onChange: ChangeHandler<T>;
};

export type FilterOptionProps<T extends SubjectCategory> = {
  name: string;
  option: T;
  selected: T[];
  onChange: ChangeHandler<T>;
};

const FilterOption = <T extends SubjectCategory>({
  name,
  option,
  selected,
  onChange,
}: FilterOptionProps<T>) => {
  const handlePress = useCallback(() => {
    onChange(option, selected);
  }, [onChange, option, selected]);

  return (
    <TouchableOpacity
      key={option}
      style={{
        flexDirection: "row",
        alignItems: "center",
        marginVertical: 4,
      }}
      onPress={handlePress}
    >
      <Checkbox.Android
        status={selected.includes(option) ? "checked" : "unchecked"}
        onPress={handlePress}
        color="#3988BD"
      />
      <Text variant="bodyLarge" style={{ marginRight: 8 }}>
        {name}
      </Text>
    </TouchableOpacity>
  );
};

export default function FilterButton<T extends SubjectCategory>({
  options,
  selected,
  onChange,
}: FilterButtonProps<T>) {
  const theme = useAppTheme();

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <Menu
      visible={isOpen}
      onDismiss={toggleIsOpen}
      anchor={
        <IconButton
          icon="filter-outline"
          iconColor={theme.colors.onBackground}
          mode="contained"
          containerColor={theme.colors.background}
          accessibilityLabel="filter"
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderColor: theme.colors.backgroundBorder,
            height: 40,
          }}
          onPress={toggleIsOpen}
        />
      }
    >
      <Text style={{ fontSize: 16, paddingLeft: 10 }}>Filter by:</Text>
      {Object.keys(options).map((name) => {
        const option = options[name];

        return (
          <FilterOption
            key={option}
            name={name}
            option={option}
            selected={selected}
            onChange={onChange}
          />
        );
      })}
    </Menu>
  );
}
