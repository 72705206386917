import {
  query,
  onSnapshot,
  CollectionReference,
  DocumentData,
  QueryConstraint,
  QuerySnapshot,
  DocumentReference,
  DocumentSnapshot,
} from "firebase/firestore";

import { Observable, Subscriber } from "zen-observable-ts";

// zen-observable-ts does not export the type we need here.
type SubscriptionObserver<T> = Parameters<Subscriber<T>>[0];

type SnapshotHandler<T> = (
  subscriber: SubscriptionObserver<T>,
  snapshot: QuerySnapshot<DocumentData>
) => void;

type DocumentSnapshotHandler<T> = (
  subscriber: SubscriptionObserver<T>,
  snapshot: DocumentSnapshot<DocumentData>
) => void;

const defaultHandleQuerySnapshot = <T>(
  subscriber: SubscriptionObserver<T[]>,
  snapshot: QuerySnapshot<DocumentData>
) => {
  const docs = snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }) as T);
  subscriber.next(docs);
};

const defaultHandleDocumentSnapshot = <T>(
  subscriber: SubscriptionObserver<T>,
  snapshot: DocumentSnapshot<DocumentData>
) => {
  subscriber.next({ ...snapshot.data(), id: snapshot.id } as T);
};

const subscribe = <T>(
  coll: CollectionReference<DocumentData>,
  queryConstraints: QueryConstraint[],
  handleSnapshot: SnapshotHandler<T[]>
) => {
  const q = query(coll, ...queryConstraints);

  return new Observable<T[]>((subscriber) => {
    try {
      return onSnapshot(q, (snapshot) => {
        handleSnapshot(subscriber, snapshot);
      });
    } catch (err) {
      subscriber.error(err);
    }
  });
};

const subscribeDoc = <T>(
  docRef: DocumentReference<DocumentData>,
  handleSnapshot: DocumentSnapshotHandler<T>
) => {
  return new Observable<T>((subscriber) => {
    try {
      return onSnapshot(docRef, (snapshot) => {
        handleSnapshot(subscriber, snapshot);
      });
    } catch (err) {
      subscriber.error(err);
    }
  });
};

export const subscribeCollection = <T>(
  coll: CollectionReference<DocumentData>,
  ...queryConstraints: QueryConstraint[]
) => {
  return subscribe<T>(coll, queryConstraints, defaultHandleQuerySnapshot);
};

export const subscribeDocument = <T>(docRef: DocumentReference<DocumentData>) => {
  return subscribeDoc<T>(docRef, defaultHandleDocumentSnapshot);
};
