import "@expo/match-media";
import "react-native-get-random-values"; // polyfill for uuid

import { GestureHandlerRootView } from "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";

import { SUBContextProvider } from "@reasongcp/react-fire-sub";

import Routes from "./routes/router";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import { firebaseConfig } from "./Firebase/firebase";
import AppThemeProvider from "./lib/AppThemeProvider";
import AppApolloProvider from "./components/shared/ApolloClient";
import MapControllerProvider from "./components/SubjectMap/MapControllerProvider";

const authCookieName = "auditaware_cookie";
const emulate = !!process.env.EXPO_PUBLIC_EMULATOR_HOST;

export default function App() {
  const gqlApiUri = process.env.EXPO_PUBLIC_AUDITAWARE_API_URL;

  if (!gqlApiUri) {
    throw new Error("EXPO_PUBLIC_AUDITAWARE_API_URL must be set.");
  }

  return (
    <Provider store={store}>
      <SUBContextProvider
        config={firebaseConfig}
        authCookieName={authCookieName}
        emulate={emulate}
      >
        <AppApolloProvider uri={gqlApiUri}>
          <AppThemeProvider>
            <StatusBar />
            {/* Gesturehandler required for android bottomsheet */}
            <GestureHandlerRootView style={{ flex: 1 }}>
              <MapControllerProvider>
                <Routes />
              </MapControllerProvider>
            </GestureHandlerRootView>
          </AppThemeProvider>
        </AppApolloProvider>
      </SUBContextProvider>
    </Provider>
  );
}
