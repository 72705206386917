import { useCallback } from "react";

// useRenderMemoizedComponent wraps the given Component in a render function
// with a stable identity. The render function accepts an optional argument
// that will be passed as props to the Component when it is rendered.
const useRenderMemoizedComponent = (Comp: React.ComponentType) =>
  useCallback(
    (props?: React.ComponentProps<typeof Comp>) =>
      <Comp {...(props || {})}/>,
    [Comp],
  );

export default useRenderMemoizedComponent;
