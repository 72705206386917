export type SubjectStatus = string;

export enum SubjectStatuses {
  NONE = "None",
  DISMISSED = "Dismissed",
  CASE_CREATED = "Case Created",
}

export const SubjectStatusesTransient = [SubjectStatuses.NONE] as const;
export const SubjectStatusesTerminal = [
  SubjectStatuses.DISMISSED,
  SubjectStatuses.CASE_CREATED,
] as const;
