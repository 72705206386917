import { useBottomSheet } from "@gorhom/bottom-sheet";

// useAppBottomSheet is the same as useBottomSheet, but returns null if a
// sheet is not available.
const useAppBottomSheet = () => {
  // This is a hack, because we want to call useBottomSheet everywhere, and
  // just get null if there is no sheet (i.e. we are on web).
  // @gorhom/bottom-sheet, however, explicitly throws if the context is null
  // and does not export the necessary pieces to work around this another way.
  //
  // In theory, we could break a Hook rule and add a platform check before the
  // useBottomSheet call. Since the platform does not change for the life of
  // the app, it would not cause any actual issues.
  try {
    return useBottomSheet();
  } catch {
    return null;
  }
};

export default useAppBottomSheet;
