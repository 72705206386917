import { useMemo } from "react";

import { useAppSelector } from "../redux/hooks";
import { selectAssignees } from "../redux/assigneeListSlice";
import { ASSIGNEE_UNKNOWN, ASSIGNEE_UNASSIGNED } from "../types";

const useAssigneeSelections = () => {
  const assignees = useAppSelector(selectAssignees);

  return useMemo(() => {
    const unassigned = {
      value: ASSIGNEE_UNASSIGNED,
      _id: "",
    };

    const selections = assignees.map((assignee) => ({
      value: assignee.displayName || assignee.email || ASSIGNEE_UNKNOWN,
      _id: assignee.id,
    }));

    return [unassigned, ...selections];
  }, [assignees]);
};

export default useAssigneeSelections;
