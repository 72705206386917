import { ComponentProps } from "react";
import { Platform, ScrollView } from "react-native";
import { BottomSheetScrollView } from "@gorhom/bottom-sheet";

const isWeb = Platform.OS === "web";

type ScrollViewProps = ComponentProps<typeof ScrollView>
type BottomSheetScrollViewProps = ComponentProps<typeof BottomSheetScrollView>

export type PlatformScrollViewProps =
    | ScrollViewProps
    | BottomSheetScrollViewProps;

const PlatformScrollView: React.FC<PlatformScrollViewProps> = (props) => {
  if (isWeb) {
    return <ScrollView {...(props as ScrollViewProps)} />;
  }

  return <BottomSheetScrollView {...(props as BottomSheetScrollViewProps)} />;
}

export default PlatformScrollView;
