import { FieldValue } from "firebase/firestore";

// NOTE: These were largely copied from the auditaware-types package.

// Metadata and Subject were moved from contractor code in /types.ts.
export type Metadata = {
  dcSourceId: string;
  dcStatus: string;
  Parcel_ID: string;
  Unique_ID: string;
  Score: string;
  latitude: number;
  longitude: number;
};

export type Subject = {
  name: string;
  address: string;
  city?: string;
  zipCode?: string;
  stateAbbr?: string;
  email?: string;
  yearBusinessOpened?: string;
  phoneNumbers?: string[];
  altNames?: string[];
  websites?: string[];
  phoneNumber: string;
  secondaryName: string;
  website: string;
  createdAt?: FieldValue;
  updatedAt?: FieldValue;
  metadata?: Metadata;
  createdByUid?: string;
  subjectType: "Organization" | "Person";
  cases: string[];
};

export type AddressInput = {
  address: string;
  city: string;
  stateAbbr: string;
  zipCode: string;
}

export type LocationInput = AddressInput & {
  parcelId?: string;
  jurisdictionName?: string;
  countyName?: string;
  googleBusinessId?: string;
  googlePlaceId?: string;
  latitude?: number;
  longitude?: number;
}

export type ContactInput = AddressInput & {
  primaryName: string;
  secondaryName?: string;
  aliasName?: string;
  phoneNumber?: string;
  email?: string;
  facebookUrl?: string;
  twitterUrl?: string;

  // Individual fields
  salutation?: string;

  // Organization fields
  website?: string;
  naics?: string;
}

export type NoteInput = {
  text: string;
};

export type AttachmentInput = {
  id?: string;
  url?: string;
  fileName: string;
  contentType: string;
  description?: string;
};

export enum CaseStatus {
  New = "NEW",
  ToDo = "TO_DO",
  NoticeSent = "NOTICE_SENT",
  ApptScheduled = "APPT_SCHEDULED",
  InProgress = "IN_PROGRESS",
  Review = "REVIEW",
  Invoice = "INVOICE",
  Completed = "COMPLETED",
}

export type CaseInput = {
  status?: CaseStatus;
  subjectId?: string;
  caseType?: string;
  primaryContact?: ContactInput;
  secondaryContact?: ContactInput;
  locationId?: string;
  assigneeUid?: string;
  feeAmount?: number;
  feeInvoiced?: boolean;
  feeSize?: string;

  location?: LocationInput;
};

export type ComplexCaseInput = {
  orgId: string;
  subjectInput: SubjectInput;
  caseInput: CaseInput;
  noteInputs: NoteInput[];
  attachmentInputs: AttachmentInput[];
}

export type SubjectType = "Organization" | "Person";

export type SubjectInput = {
  externalId?: string;
  subjectType: string;
  sourceId: string;
  firstAuditYear: string | number;
  location: LocationInput;
  contact: ContactInput;
}
