import { collection } from "firebase/firestore";

import { db } from "../firebase";
import { subscribeCollection } from "./helpers";
import { Assignee } from "../../lib/types/assignee";


export const subscribeAssignees = (organizationId: string) => {
  return subscribeCollection<Assignee>(
    collection(db, `organizations/${organizationId}/members`),
  );
};
