import React from "react";
import { StyleSheet, View } from "react-native";
import { Text } from "react-native-paper";

export type NoDataProps = {
  text: string;
}

const stylesheet = StyleSheet.create({
  noData: {
    marginTop: 50,
  },
  noDataText: {
    textAlign: "center",
    opacity: 0.5,
    textTransform: "uppercase",
    fontStyle: "italic",
    fontWeight: "bold",
  },
});

const NoData: React.FC<NoDataProps> = ({ text }) => {
  return (
    <View style={stylesheet.noData}>
      <Text style={stylesheet.noDataText}>{text}</Text>
    </View>
  );
};

export default NoData;
