import { store } from "../redux/store";
import {
  setSnackbar,
  SetSnackbarPayload,
  SnackbarSeverity,
} from "../redux/snackbarSlice";

const partialNotify = (
  defaults: Partial<SetSnackbarPayload> = {},
  dispatch = store.dispatch
) => {
  return (
    message = defaults.message || "",
    href?: string,
    payload: Partial<SetSnackbarPayload> = {},
  ) => {
    return dispatch(
      setSnackbar({
        severity: "success" as SnackbarSeverity,
        duration: 10000,
        ...defaults,
        ...payload,
        message,
        href,
      })
    );
  };
};

export default partialNotify;
