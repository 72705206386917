import React from "react";
import { Platform } from "react-native";

type OnlyPlatformProps = {
  children: React.ReactNode;
  web?: boolean;
  mobile?: boolean;
};

const isWeb = Platform.OS === "web";

const OnlyPlatform = ({ children, web, mobile }: OnlyPlatformProps) => {
  switch (true) {
    case web && isWeb:
    case mobile && !isWeb:
      return children;
    default:
      return null;
  }
};

const OnlyWeb: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <OnlyPlatform web>{children}</OnlyPlatform>
);

const OnlyMobile: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <OnlyPlatform mobile>{children}</OnlyPlatform>
);

OnlyPlatform.Web = OnlyWeb;
OnlyPlatform.Mobile = OnlyMobile;

export default OnlyPlatform;
