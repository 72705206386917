import { useEffect, useState } from "react";

import type { Map } from "../../lib/MapAPI";
import { Subject } from "../../lib/types/subject";
import { MapController } from "../../lib/MapAPI";

// Animate to the provided subject any time the subject object changes, but
// only do it once.
const useAnimateToSubject = <T extends Map>(
  subject: Subject | null,
  controller: MapController<T>,
) => {
  const [lastBiz, setLastBiz] = useState<Subject | null>(null);

  useEffect(() => {
    if (!subject && lastBiz) setLastBiz(null);
    if (!subject) return;

    // Don't reanimate to this subject if we've already done it once.
    if (subject.id === lastBiz?.id) return;
    setLastBiz(subject);

    const { latitude, longitude } = subject;
    const { latitudeDelta } = controller.getDelta() || {};

    // If we have a delta for latitude, then we know that we are on mobile.
    // If we are on Mobile, then there's a BottomSheet covering the bottom
    // half of the screen, so we need to center the pin in only the top half,
    // i.e. 1/4 of the way from the top.
    //
    // Is this is a small bleed through of our abstractions, but it's small
    // and contained, so not worth sweating over it.
    const offset = latitudeDelta ? (latitudeDelta / 4) : 0;

    controller.animateTo({
      latitude: Number(latitude) - offset,
      longitude: Number(longitude),
    });
  }, [lastBiz, controller, subject]);
};

export default useAnimateToSubject;
