import React, { useState } from "react";
import {
  View,
  ImageBackground,
  KeyboardAvoidingView,
  TouchableWithoutFeedback,
  Keyboard,
  Platform,
} from "react-native";
import { HelperText, TextInput, Button, Text } from "react-native-paper";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { FirebaseError } from "@firebase/util";

import GoBackButton from "../components/goBackButton";
import { setSnackbar } from "../redux/snackbarSlice";
import { useAppDispatch } from "../redux/hooks";
import useAppNavigation from "../hooks/useAppNavigation";
import splash from "../assets/splash.png";

export default function ForgotPasswordScreen() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();

  function handleEmail(value: string) {
    setEmail(value);
    if (value.length > 0 && emailError) {
      setEmailError(false);
    }
  }

  const handleLogin = async () => {
    if (email.length < 1) {
      setEmailError(true);
    } else {
      setLoading(true);
      try {
        await sendPasswordResetEmail(auth, email);
      } catch (error: unknown) {
        if (error instanceof FirebaseError) {
          console.error(error.message);
        }
      }
      dispatch(
        setSnackbar({
          severity: "success",
          message: "Password reset email sent successfully!",
        })
      );
      navigation.goBack();
      setLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <TouchableWithoutFeedback
        onPress={Keyboard.dismiss}
        disabled={Platform.OS === "web"}
      >
        <>
          <ImageBackground
            source={splash}
            resizeMode="cover"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <GoBackButton overrideStyle={true} />
            <View style={{ display: "flex", alignItems: "center" }}>
              <View
                style={{
                  width: "90%",
                  maxWidth: 500,
                  padding: 20,
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <Text
                  style={{
                    fontSize: 25,
                    color: "white",
                    fontWeight: "400",
                    marginVertical: 12,
                  }}
                >
                  Password Recovery
                </Text>
                <Text
                  style={{
                    fontSize: 17,
                    color: "white",
                  }}
                >
                  Enter the email associated with the account and we will send
                  instructions to reset your account.
                </Text>
                <View style={{ width: "100%", marginTop: 50 }}>
                  <TextInput
                    mode="flat"
                    autoCapitalize="none"
                    blurOnSubmit={false}
                    label="Email"
                    value={email}
                    onChangeText={handleEmail}
                    error={emailError}
                    style={{ backgroundColor: "transparent" }}
                    selectionColor="yellow"
                    textColor="white"
                    underlineColor="white"
                    activeUnderlineColor="white"
                    theme={{
                      colors: {
                        onSurfaceVariant: "white",
                      },
                    }}
                  />
                  <HelperText
                    type="error"
                    visible={emailError}
                    style={{
                      marginBottom: 10,
                      fontSize: 16,
                    }}
                  >
                    Invalid Email Address
                  </HelperText>
                </View>
                <Button
                  mode="elevated"
                  icon="send"
                  loading={loading}
                  disabled={loading}
                  onPress={handleLogin}
                  textColor={"white"}
                  buttonColor={"#00101B"}
                  style={{
                    borderRadius: 2,
                    paddingVertical: 5,
                    marginTop: 50,
                  }}
                >
                  {loading ? "" : "Send Email"}
                </Button>
              </View>
            </View>
          </ImageBackground>
        </>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  );
}
