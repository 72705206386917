import { useResidenceFromRoute } from "../hooks/useBusinessFromRoute";
import { selectOrganization } from "../redux/organizationSlice";
import { selectUser } from "../redux/userSlice";
import { useAppSelector } from "../redux/hooks";
import Pordal from "../components/shared/Pordal";
import ResidenceEditForm
  from "../components/ResidenceDetails/ResidenceEditForm";

export default function EditScreen() {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const residence = useResidenceFromRoute();

  if (!residence || !organization || !user) {
    return <></>;
  }

  return (
    <Pordal>
      <ResidenceEditForm
        residence={residence}
        organization={organization}
        user={user}
      />
    </Pordal>
  );
}
