import * as ImagePicker from "expo-image-picker";
import mime from "mime";

const useImageSelector = async () => {
  // No permissions request is necessary for launching the image library
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.Images,
    allowsEditing: true,
    aspect: [4, 3],
    // image compression
    quality: 0.2,
  });

  if (result.canceled) {
    return null;
  }

  const { uri } = result.assets[0];
  const fileName =uri.split("/").pop() || "unkown.jpg";
  const contentType = mime.getType(fileName) || "image/jpeg";

  return {
    imageUrl: uri,
    imageDescription: "",
    fileName,
    contentType,
  };
};

export default useImageSelector;
