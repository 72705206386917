import { Platform } from "react-native";

import type { IsomorphicMapProps, Mappable } from "./types.d";
import IsomorphicWebMap from "./Web";
import IsomorphicMobileMap from "./Mobile";

const MapComponent = Platform.OS === "web" ?
  IsomorphicWebMap :
  IsomorphicMobileMap;

// IsomomorphicMap provides a unified interface for rendering "Mappable"
// entities (i.e. Businesses) across a map view on both Web and Mobile.
//
// Avoid putting any business logic in this folder. It is here to hide the
// nitty-gritty details of Mobile vs. Web map views. It shouldn't know
// anything about Businesses, Residences, or anything else. Business Logic
// should be handled higher up, and data should be passed down via Props.
const IsomorphicMap = <T extends Mappable>(
  props: IsomorphicMapProps<T>,
) => <MapComponent {...props} />;

export * from "./types.d";
export default IsomorphicMap;
