import { View } from "react-native";

import { stylesheet } from "./styles";
import ActionItem from "./ActionItem";

export type ActionFlowProps = {
  children: React.ReactNode;
}

const ActionFlowContainer: React.FC<ActionFlowProps> = ({ children }) => {
  return (
    <View style={stylesheet.actionFlow}>
      {children}
    </View>
  );
};

const ActionFlow = Object.assign(
  ActionFlowContainer,
  { Item: ActionItem },
);

export default ActionFlow;
