import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Image,
  ImageBackground,
  Linking,
  SafeAreaView,
  ScrollView,
  View,
} from "react-native";
import { Avatar, Button, Divider, Drawer, List, Text } from "react-native-paper";
import { DrawerActions } from "@react-navigation/native";
import { useAppTheme } from "../theme";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../Firebase/firebase";

import { useSignOut } from "@reasongcp/react-fire-sub";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  changeCurrentOrganization,
  selectOrganization,
  selectOrganizations,
  subscribeOrganizationsToFirestore,
  subscribeOrganizationToFirestore,
} from "../redux/organizationSlice";

import { ThemeModeEnum } from "../lib/AppThemeProvider";
import useThemeMode from "../hooks/useThemeMode";
import useThemeName from "../hooks/useThemeName";
import { selectUser } from "../redux/userSlice";
import { Organization } from "../types";
import useAppNavigation from "../hooks/useAppNavigation";
import useApplicationModeName from "../hooks/useApplicationModeName";

import drawerLight from "../assets/Drawer-light.png";
import drawerDark from "../assets/Drawer-Dark.png";

const themeModesCycleMap = {
  [ThemeModeEnum.LIGHT]: ThemeModeEnum.DARK,
  [ThemeModeEnum.DARK]: ThemeModeEnum.SYSTEM,
  [ThemeModeEnum.SYSTEM]: ThemeModeEnum.LIGHT,
};

// Map the current themeMode to the next themeMode.
const mapNextThemeMode = (currMode: ThemeModeEnum) => themeModesCycleMap[currMode];

const OrganizationAvatar = ({ org }: { org: Organization }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!org.imageName) return;

    const orgImageRef = ref(storage, `organizations/${org.id}/${org.imageName}`);

    getDownloadURL(orgImageRef).then(setImageUrl).catch(console.error);
  }, [org]);

  if (!imageUrl) {
    const label = (org.name || "").substring(0, 2).toUpperCase();
    return <Avatar.Text label={label} />;
  }

  return (
    <View
      style={{
        width: 60,
        height: 60,
        backgroundColor: "black",
      }}
    >
      <Image
        source={{ uri: imageUrl }}
        style={{
          width: "100%",
          height: "100%",
          resizeMode: "cover",
        }}
      />
    </View>
  );
};

const OrganizationItem = ({
  org,
  isActive,
}: {
  org: Organization;
  isActive: boolean;
}) => {
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();
  const theme = useAppTheme();

  const handlePress = useCallback(() => {
    dispatch(changeCurrentOrganization(org.id));
    navigation.dispatch(DrawerActions.closeDrawer());
  }, [dispatch, navigation, org]);

  const activeStyle = useMemo(
    () => ({
      backgroundColor: "transparent",
      borderRadius: 0,
      borderStartWidth: 2,
      borderStartColor: theme.colors.primary,
      height: 50,
    }),
    [theme]
  );

  const style = useMemo(
    () => (isActive ? activeStyle : { backgroundColor: "transparent" }),
    [isActive, activeStyle]
  );

  return (
    <Drawer.Item
      key={org.id}
      style={style}
      icon="domain"
      label={org.name || ""}
      active={isActive}
      onPress={handlePress}
    />
  );
};

export function DrawerContent() {
  const navigation = useAppNavigation();
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const organization = useAppSelector(selectOrganization);
  const myOrganizations = useAppSelector(selectOrganizations);

  const theme = useAppTheme();
  const themeName = useThemeName();
  const [themeMode, setThemeMode] = useThemeMode();

  const signOut = useSignOut(false);

  useEffect(() => {
    if (!user) return;

    const orgsSub = dispatch(subscribeOrganizationsToFirestore(user.id));
    const orgSub = dispatch(subscribeOrganizationToFirestore(user.currentOrganization));

    return () => {
      orgSub.unsubscribe();
      orgsSub.unsubscribe();
    };
  }, [user, dispatch]);

  const logout = useCallback(() => {
    navigation.dispatch(DrawerActions.closeDrawer());
    signOut();
  }, [signOut, navigation]);

  const handleToggleTheme = useCallback(() => {
    setThemeMode(mapNextThemeMode);
  }, [setThemeMode]);

  const appModeName = useApplicationModeName();
  const toggleBetweenInsightModes = useCallback(() => {
    switch (appModeName) {
      case "Businesses":
        navigation.navigate("Residences");
        break;
      case "Residences":
        navigation.navigate("Businesses");
        break;
      default:
        console.error("Unknown route name", name);
    }
  }, [navigation, appModeName]);

  const handleKnowledgeBaseLink = () => {
    const url = "https://reasonconsulting.atlassian.net/wiki/spaces/AAKB/overview";
    Linking.openURL(url);
  };

  if (!organization) return null;

  return (
    <ImageBackground
      source={themeName === "light" ? drawerLight : drawerDark}
      resizeMode="cover"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <SafeAreaView
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100%",
          paddingVertical: 20,
        }}
      >
        <View style={{ flex: 1 }}>
          <Drawer.Section
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 20,
              padding: 4,
              marginLeft: 45,
              marginRight: 40,
            }}
          >
            <OrganizationAvatar org={organization} />

            <View style={{ marginLeft: 6 }}>
              <Text variant="bodyLarge">{user?.displayName}</Text>
              <Text variant="bodySmall">{user?.email}</Text>
            </View>
          </Drawer.Section>
          <Divider horizontalInset style={{ marginBottom: 20 }} />
          <Button
            mode="outlined"
            icon={appModeName === "Businesses" ? "home" : "domain"}
            onPress={toggleBetweenInsightModes}
            textColor={theme.colors.onBackground}
            style={{
              borderColor: theme.colors.onBackground,
              borderRadius: 4,
              margin: 12,
            }}
          >
            {appModeName === "Businesses" ? "Residence Insights" : "Business Insights"}
          </Button>
          {myOrganizations.length > 1 && (
            <ScrollView>
              <List.Accordion
                theme={{ colors: { background: "transparent" } }}
                title="Organizations"
              >
                {myOrganizations.map((org) => {
                  return (
                    <OrganizationItem
                      key={org.id}
                      org={org}
                      isActive={org.id === organization.id}
                    />
                  );
                })}
              </List.Accordion>
            </ScrollView>
          )}
        </View>

        <View>
          <Button
            mode="outlined"
            icon="help"
            textColor={theme.colors.onBackground}
            style={{
              borderColor: theme.colors.onBackground,
              borderRadius: 4,
              margin: 12,
            }}
            onPress={handleKnowledgeBaseLink}
          >
            Need Help
          </Button>
          <Button
            mode="outlined"
            icon="theme-light-dark"
            onPress={handleToggleTheme}
            textColor={theme.colors.onBackground}
            style={{
              borderColor: theme.colors.onBackground,
              borderRadius: 4,
              margin: 12,
            }}
          >
            Theme &ndash; {themeMode}
          </Button>
          <Button
            mode="outlined"
            icon="logout"
            onPress={logout}
            textColor={theme.colors.onBackground}
            style={{
              borderColor: theme.colors.onBackground,
              borderRadius: 4,
              margin: 12,
            }}
          >
            Logout
          </Button>
        </View>
      </SafeAreaView>
    </ImageBackground>
  );
}
