import { FC } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

import { Business, Note } from "../../types";
import NoData from "../shared/NoData";
import residenceStyles from "../DetailStyles";

interface BusinessNotesProps {
  business: Business;
  notes: Note[];
}

const BusinessNotes: FC<BusinessNotesProps> = ({ notes }) => {

  return (
    <View style={residenceStyles.detailBlock}>
      {notes.length === 0 && <NoData text="No Notes" />}
      {notes.map((note) => (
        <Text key={note.uuid} style={{ marginBottom: 6 }}>
          {note.text}
        </Text>
      ))}
    </View>
  );
};

export default BusinessNotes;
