import { useCallback, useMemo, useState, FC } from "react";
import { Platform, ScrollView } from "react-native";
import { DataTable, IconButton, Menu } from "react-native-paper";
import { useAppTheme } from "../../../theme";
import { useAppSelector } from "../../../redux/hooks";
import {
  selectBusinessSnapshotSummaryReport,
  selectResidenceSnapshotSummaryReport,
} from "../../../redux/snapshotSummaryReport";
import SnapshotSummaryReportPagination from "./Pagination";
import { SubjectNameType } from "../../../types";

type SnapshotSummaryReportProps = {
  type: SubjectNameType;
};

const SnapshotSummaryReport: FC<SnapshotSummaryReportProps> = ({ type }) => {

  if (Platform.OS !== "web") {
    return null;
  }

  const theme = useAppTheme();
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const [currentAssignees, setCurrentPageObjects] = useState<string[]>([]);
  const snapshotReport = useAppSelector(
    type === "Business"
      ? selectBusinessSnapshotSummaryReport
      : selectResidenceSnapshotSummaryReport
  );

  const {
    categories: allCategories,
    assigneeNames: assigneeDisplayName,
    assigneeCategories: assigneeCategorySnapshot,
  } = snapshotReport;
  const { totals: statusTotals } = assigneeCategorySnapshot;

  const allPagedObjects = useMemo(
    () => Object.keys(assigneeDisplayName),
    [assigneeDisplayName]
  );

  return (
    <Menu
      visible={isOpen}
      onDismiss={handleToggleIsOpen}
      anchor={
        <IconButton
          icon="chart-box"
          iconColor={theme.colors.onBackground}
          mode="contained"
          containerColor={theme.colors.background}
          accessibilityLabel="filter"
          size={40}
          style={{
            borderColor: theme.colors.backgroundBorder,
            height: 50,
            backgroundColor: "transparent",
          }}
          onPress={handleToggleIsOpen}
        />
      }
    >
      <ScrollView>
        <DataTable>
          <DataTable.Header>
            <DataTable.Title style={{ minWidth: 120, justifyContent: "center" }}>
              Assignee ID
            </DataTable.Title>
            {allCategories.map((category) => (
              <DataTable.Title
                key={category}
                style={{ minWidth: 120, justifyContent: "center" }}
              >
                {category}
              </DataTable.Title>
            ))}
            <DataTable.Title style={{ minWidth: 120, justifyContent: "center" }}>
              Total
            </DataTable.Title>
          </DataTable.Header>
          {currentAssignees.map((assigneeId) => (
            <DataTable.Row
              key={assigneeId}
              style={{ minWidth: 120, justifyContent: "center" }}
            >
              <DataTable.Cell style={{ minWidth: 120, justifyContent: "flex-start" }}>
                {assigneeDisplayName[assigneeId]}
              </DataTable.Cell>
              {allCategories.map((category) => (
                <DataTable.Cell
                  numeric
                  key={category}
                  style={{ minWidth: 120, justifyContent: "center" }}
                >
                  {assigneeCategorySnapshot[assigneeId]?.[category] || 0}
                </DataTable.Cell>
              ))}
              <DataTable.Cell style={{ minWidth: 120, justifyContent: "center" }}>
                {assigneeCategorySnapshot[assigneeId]?.total || 0}
              </DataTable.Cell>
            </DataTable.Row>
          ))}
          <DataTable.Row>
            <DataTable.Cell style={{ minWidth: 120, justifyContent: "flex-start" }}>
              Total
            </DataTable.Cell>
            {allCategories.map((category) => (
              <DataTable.Cell
                numeric
                key={category}
                style={{ minWidth: 120, justifyContent: "center" }}
              >
                {statusTotals[category]}
              </DataTable.Cell>
            ))}
            <DataTable.Cell style={{ minWidth: 120, justifyContent: "center" }}>
              {statusTotals.total}
            </DataTable.Cell>
          </DataTable.Row>
          <SnapshotSummaryReportPagination
            allPagedObjects={allPagedObjects}
            setCurrentPageObjects={setCurrentPageObjects}
          />
        </DataTable>
      </ScrollView>
    </Menu>
  );
};
export default SnapshotSummaryReport;
