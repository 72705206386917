import { useEffect } from "react";
import { subscribeAssigneesToFirestore } from "../redux/assigneeListSlice";
import { RootState } from "../redux/store";
import { useAppDispatch } from "../redux/hooks";
import { useSelector } from "react-redux";

function useAssigneeSubscribe() {
  const dispatch = useAppDispatch();
  const organizationId = useSelector(
    (state: RootState) => state.organization.organization?.id
  );

  useEffect(() => {
    if (!organizationId) return;

    const subscription = dispatch(subscribeAssigneesToFirestore(organizationId));
    return () => subscription.unsubscribe();
  }, [dispatch, organizationId]);
}

export default useAssigneeSubscribe;
