import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Observable } from "zen-observable-ts";

import {
  Business,
  BusinessCategories,
  BusinessCategory,
  BusinessStatusesTerminal,
} from "../types";
import { AppDispatch, RootState } from "./store";
import { notifyAndLogError } from "./snackbarSlice";
import {
  subscribeBusiness,
  subscribeBusinesses,
  subscribeBusinessGroup,
} from "../Firebase/queries/businesses";
import { selectFilterAssignees } from "./assigneeListSlice";
import {
  filterByAssigneesFunc,
  makeFilterSubjectsFunc,
  makeSearchSubjectsFunc,
} from "./subjectResultFuncs";

export interface BusinessListState {
  selectedBusiness: Business | null;
  businesses: Business[];
  colocated: Business[];
  filterOptions: BusinessCategory[];
  businessSearchText: string;
  loadingBusinesses: boolean;
}

const isTerminalStatus = ({ status }: Pick<Business, "status">) => {
  return (BusinessStatusesTerminal as ReadonlyArray<string>).includes(status);
};

const initialState: BusinessListState = {
  businesses: [],
  colocated: [],
  selectedBusiness: null,
  filterOptions: [],
  businessSearchText: "",
  loadingBusinesses: false,
};

const businessList = createSlice({
  name: "businessList",
  initialState,
  reducers: {
    setSelectedBusiness: (state, action: PayloadAction<Business | null>) => {
      state.selectedBusiness = action.payload;
    },
    setBusinesses: (state, action: PayloadAction<Business[]>) => {
      state.businesses = action.payload;
    },
    setColocated: (state, action: PayloadAction<Business[]>) => {
      state.colocated = action.payload;
    },
    setFilterOptions: (state, action: PayloadAction<BusinessCategory>) => {
      const option = action.payload;

      if (state.filterOptions.includes(option)) {
        const index = state.filterOptions.indexOf(option);

        state.filterOptions = [
          ...state.filterOptions.slice(0, index),
          ...state.filterOptions.slice(index + 1),
        ];
      } else {
        state.filterOptions = [...state.filterOptions, option];
      }
    },
    setBusinessSearchText: (state, action: PayloadAction<string>) => {
      state.businessSearchText = action.payload;
    },
    setLoadingBusinesses: (state, action: PayloadAction<boolean>) => {
      state.loadingBusinesses = action.payload;
    },
  },
});

export const {
  setBusinesses,
  setColocated,
  setFilterOptions,
  setBusinessSearchText,
  setSelectedBusiness,
  setLoadingBusinesses,
} = businessList.actions;

export default businessList.reducer;

// Thunks

// subscribeBusinessesToFirestore is a thunk that subscribes to the
// businesses collection in Firestore and updates the Redux store
// with the latest data. It also updates the selectedBusiness if
// it is in the store. Any errors are logged to the console and
// a snackbar is shown to the user.
export const subscribeBusinessesToFirestore =
  (orgId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setLoadingBusinesses(true));
    const { loadingBusinesses, selectedBusiness } = getState().businessList;

    const next = (businesses: Business[]) => {
      dispatch(setBusinesses(businesses));
      if (loadingBusinesses) dispatch(setLoadingBusinesses(false));

      if (selectedBusiness) {
        const currBusiness = businesses.find((b) => b.id === selectedBusiness.id);
        dispatch(setSelectedBusiness(currBusiness || null));
      }
    };

    const error = (err: Error) =>
      dispatch(notifyAndLogError(err, "Error loading Businesses"));

    return subscribeBusinesses(orgId).subscribe(next, error);
  };

export const subscribeBusinessToFirestore =
  (orgId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
    const { selectedBusiness } = getState().businessList;

    if (!selectedBusiness) return Observable.of(null).subscribe(() => null);

    return subscribeBusiness(orgId, selectedBusiness.id).subscribe(
      (biz: Business) => dispatch(setSelectedBusiness(biz)),
      (err: Error) => dispatch(notifyAndLogError(err, "Error loading Business"))
    );
  };

export const subscribeColocatedToFirestore =
  (orgId: string, groupId: string) => (dispatch: AppDispatch) => {
    const next = (businesses: Business[]) => dispatch(setColocated(businesses));

    const error = (err: Error) =>
      dispatch(notifyAndLogError(err, "Error loading Colocated Businesses"));

    return subscribeBusinessGroup(orgId, groupId).subscribe(next, error);
  };

// Selectors

export const selectBusinesses = (state: RootState) => state.businessList.businesses;
export const selectColocated = (state: RootState) => state.businessList.colocated;
export const selectFilterOptions = (state: RootState) => state.businessList.filterOptions;
export const selectLoadingBusinesses = (state: RootState) =>
  state.businessList.loadingBusinesses;
export const selectSelectedBusiness = (state: RootState) =>
  state.businessList.selectedBusiness;

// Find the business in the business list with the given ID.
export const selectBusinessWithId = createSelector(
  [selectBusinesses, (state: RootState, id: string) => id],
  (businesses, id) => businesses.find((b) => b.id === id) || null
);

export const selectHasSelectedBusiness = createSelector(
  [selectSelectedBusiness],
  (selectedBusiness) => !!selectedBusiness
);

export const selectBusinessSearchText = (state: RootState) =>
  state.businessList.businessSearchText;

export const selectFilteredBusinesses = createSelector(
  [selectBusinesses, selectFilterOptions],
  makeFilterSubjectsFunc(BusinessCategories)
);

export const selectAssignedBusinesses = createSelector(
  [selectFilteredBusinesses, selectFilterAssignees],
  filterByAssigneesFunc
);

// final selector after all searching and filtering
export const selectBusinessResults = createSelector(
  [selectAssignedBusinesses, selectBusinessSearchText],
  makeSearchSubjectsFunc(["name", "address"])
);

export const selectToDoBusinesses = createSelector(
  [selectBusinessResults],
  (businesses) => businesses.filter((b) => !isTerminalStatus(b))
);

export const selectDoneBusinesses = createSelector(
  [selectBusinessResults],
  (businesses) => businesses.filter(isTerminalStatus)
);
