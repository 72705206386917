import { useEffect } from "react";

import { AppAction } from "../redux/store";
import { useAppDispatch } from "../redux/hooks";
import { Subject } from "../lib/types/subject";

// useSelectSubject takes a Subject and an ActionCreator that "selects" the
// provided Subject, useful for "Subject Detail" screens. The dispatching
// action is wrapped in a useEffect, and the hook ensures the Subject is
// deselected when the caller unmounts.
const useSelectSubject = <T extends Subject>(
  subject: T | null,
  actionCreator: (sub: T | null) => AppAction,
) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(actionCreator(subject));
    return () => {
      dispatch(actionCreator(null));
    };
  }, [dispatch, subject, actionCreator]);
};

export default useSelectSubject;
