import { FC } from "react";
import { Text } from "react-native-paper";

interface DisplayInfoProps {
  text: string;
  onPress?: () => void;
  selectable?: boolean;
}


const DisplayInfo: FC<DisplayInfoProps> = ({
  text,
  selectable,
}) => {
  return (
    <Text
      style={{
        marginBottom: 6,
        fontSize: 14,
      }}
      selectable={selectable}
    >
      { text }
    </Text>
  );
}

export default DisplayInfo;
