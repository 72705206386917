import { A } from "@expo/html-elements";
import { FC } from "react";
import { Image, View } from "react-native";
import { useAppTheme } from "../../theme";
import { Attachment, Business } from "../../types";
import NoData from "../shared/NoData";
import residenceStyles from "../DetailStyles";

interface BusinessAttachmentProps {
  business: Business;
  attachments: Attachment[];
}

const BusinessAttachments: FC<BusinessAttachmentProps> = ({ attachments }) => {
  const theme = useAppTheme();

  return (
    <View style={residenceStyles.detailBlock}>
      {attachments.length === 0 && <NoData text="No Attachments" />}
      {attachments.map((attachment) => (
        <View key={attachment.uuid}>
          <Image
            source={{ uri: attachment.downloadUrl }}
            style={{ width: 200, height: 200 }}
          />
          <A
            href={attachment.downloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: theme.colors.onBackground,
            }}
          >
            {attachment.label}
          </A>
        </View>
      ))}
    </View>
  );
};

export default BusinessAttachments;
