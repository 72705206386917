import { useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Text, TextInput, useTheme } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import { v4 as uuidv4 } from "uuid";
import { ref, uploadBytes } from "firebase/storage";
import {
  arrayUnion,
  doc,
  runTransaction,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";

import { db, storage } from "../../Firebase/firebase";
import partialNotify from "../../lib/partialNotify";
import useAppNavigation from "../../hooks/useAppNavigation";
import AssigneeSelect from "../shared/AssigneeSelect";
import ResidenceImagePicker from "../../components/ResidenceDetails/ResidenceImagePicker";
import { createNote } from "../../Firebase/queries/notes";
import {
  Attachment,
  Residence,
  Organization,
  User,
  ResidenceInput,
  Image,
} from "../../types";

type ResidenceEditProps = {
  residence: Residence;
  organization: Organization;
  user: User;
};

const notifySaved = partialNotify({
  message: "Note saved successfully!",
  severity: "success",
});

const stylesheet = StyleSheet.create({
  residenceName: {
    marginBottom: 6,
    fontSize: 22,
    fontWeight: "bold",
  },
});

const ResidenceEditForm = ({ residence, organization, user }: ResidenceEditProps) => {
  const theme = useTheme();
  const navigation = useAppNavigation();
  const orgId = organization.id;
  const epochId = organization.residencesEpochId;
  const residenceId = residence.id;

  const goBack = useCallback(() => {
    navigation.navigate("Residence_List");
  }, [navigation]);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid, dirtyFields, isSubmitting },
  } = useForm<ResidenceInput>({
    defaultValues: {
      note: "",
      assigneeId: residence.assigneeId,
    },
  });

  const residencePath = useCallback(() => {
    return [
      "organizations",
      orgId,
      "residence_epochs",
      epochId,
      "residences",
      residenceId,
    ].join("/");
  }, [orgId, epochId, residenceId]);


  const updateResidenceMetaData = useCallback(
    (data: ResidenceInput) => {
      const path = residencePath();
      const { assigneeId = "" } = data;
      const docRef = doc(db, path);
      updateDoc(docRef, { assigneeId });
    },
    [residencePath]
  );

  const createAttachments = useCallback(
    async (images: Image[]) => {
      if (!dirtyFields.images || images.length == 0) return;

      const imageIds = images.map(() => uuidv4());
      // upload images
      images.forEach(async (image, index) => {
        const uuid = imageIds[index];
        const storageRef = ref(storage, uuid);
        const response = await fetch(image.imageUrl);
        const blob = await response.blob();
        await uploadBytes(storageRef, blob);
      });

      await runTransaction(db, async (transaction) => {
        images.forEach(async (image, index) => {
          const uuid = imageIds[index];
          const storageRef = ref(storage, uuid);
          const fullUrl = `gs://${storageRef.bucket}/${storageRef.fullPath}`;
          const imageData: Attachment = {
            uuid,
            label: image.imageDescription,
            url: fullUrl,
            storageRefPath: uuid,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            fileName: image.fileName,
            contentType: image.contentType,
          };

          // append to case attachments ids array
          const path = residencePath();
          transaction.update(
            doc(db, path),
            {
              attachments: arrayUnion(uuid),
            }
          );

          // create attachment
          const attachmentsPath = `${path}/attachments`;
          transaction.set(
            doc(db, attachmentsPath, uuid),
            imageData
          );
        });
      });
    },
    [dirtyFields.images, residencePath]
  );

  const handleCreate = useCallback(
    (data: ResidenceInput) => {
      if (!user || !organization || !residence) {
        return Promise.reject(new Error("Invalid Path"));
      }

      const path = residencePath();
      return Promise.all([
        createAttachments(data.images),
        updateResidenceMetaData(data),
        createNote({
          uid: user.id,
          text: data.note,
          parentPath: path,
        }),
      ])
        .then(goBack)
        .then(() => notifySaved());
    },
    [
      residence,
      organization,
      user,
      goBack,
      createAttachments,
      updateResidenceMetaData,
      residencePath,
    ]
  );

  const updateDatabase = useCallback(
    async (data: ResidenceInput) => {
      handleCreate(data)
        .then(goBack)
        .then(() => notifySaved());
    },
    [goBack, handleCreate]
  );

  return (
    <View>
      <View>
        <Text style={stylesheet.residenceName}>{residence.name}</Text>
      </View>
      <AssigneeSelect control={control} />
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextInput
            label="Add Note"
            mode="outlined"
            multiline={true}
            onChangeText={onChange}
            value={value}
            // only android
            numberOfLines={4}
            style={{ minHeight: 120, marginHorizontal: 20 }}
          />
        )}
        name="note"
      />
      <ResidenceImagePicker control={control} />
      <Button
        mode="contained"
        color={theme.colors.primary}
        loading={isSubmitting}
        disabled={!isDirty || !isValid || isSubmitting}
        style={{
          borderRadius: 4,
          marginHorizontal: 20,
          marginVertical: 20,
        }}
        textColor={theme.colors.onPrimary}
        onPress={handleSubmit(updateDatabase)}
      >
        Save
      </Button>
    </View>
  );
}

export default ResidenceEditForm;
