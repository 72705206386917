import { AppDispatch, RootState } from "./store";
import { Assignee } from "../lib/types/assignee";
import { notifyAndLogError } from "./snackbarSlice";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { subscribeAssignees } from "../Firebase/queries/assignee";

interface AssigneeListState {
  assignees: Assignee[];
  loadingAssignees: boolean;
  selectedAssigneeIds: string[];
}

const initialState: AssigneeListState = {
  assignees: [],
  loadingAssignees: false,
  selectedAssigneeIds: [],
};

const assigneeSlice = createSlice({
  name: "assignees",
  initialState,
  reducers: {
    setAssignees: (state, action: PayloadAction<Assignee[]>) => {
      state.assignees = action.payload;
    },
    setLoadingAssignees: (state, action: PayloadAction<boolean>) => {
      state.loadingAssignees = action.payload;
    },
    setFilterAssignees: (state, action: PayloadAction<string>) => {
      const index = state.selectedAssigneeIds.indexOf(action.payload);
      if (index >= 0) {
        state.selectedAssigneeIds.splice(index, 1);
      } else {
        state.selectedAssigneeIds.push(action.payload);
      }
    },
  },
});

export const {
  setAssignees,
  setLoadingAssignees,
  setFilterAssignees } = assigneeSlice.actions;
export default assigneeSlice.reducer;

// Thunks

export const subscribeAssigneesToFirestore = (organizationId: string) => (
  dispatch: AppDispatch,
) => {
  dispatch(setLoadingAssignees(true));

  const next = (assignees: Assignee[]) => {
    dispatch(setAssignees(assignees));
    dispatch(setLoadingAssignees(false));
  };

  const error =
    (err: Error) => dispatch(notifyAndLogError(err, "Error loading Assignee"));

  return subscribeAssignees(organizationId).subscribe(next, error);
};

// Selectors

export const selectAssignees =
  (state: RootState) => state.assigneeSlice.assignees;

export const selectLoadingAssignees =
  (state: RootState) => state.assigneeSlice.loadingAssignees;

export const selectFilterAssignees = (state: RootState) => {
  return state.assigneeSlice.selectedAssigneeIds;
};
