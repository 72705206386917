import { View, Image, DimensionValue, StyleSheet } from "react-native";

import { useAppTheme } from "../../theme";
import useThemeName from "../../hooks/useThemeName";
import DrawerButton from "../../components/drawerButton";

import { MapScreenProps } from "./types";

import logoDark from "../../assets/logo-dark.png";
import logoLight from "../../assets/logo-light.png";

const stylesheet = StyleSheet.create({
  outer: {
    width: "100%",
    // RN doesn't seem to know about vh, probably because mobile won't
    // support it. But we know this is a web only component, so we can
    // use it.
    height: "100vh" as DimensionValue,
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },

  listWrapper: {
    minWidth: 300,
    flexGrow: 1,
  },

  mapWrapper: {
    flexGrow: 4,
  },

  flexHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },

  logo: {
    width: 100,
    height: 50,
    resizeMode: "contain",
  },

  innerList: {
    flexGrow: 1,
  },
});

export default function WebMapScreen({
  renderList,
  renderMap,
}: MapScreenProps) {
  const theme = useAppTheme();
  const themeName = useThemeName();

  return (
    <View style={stylesheet.outer}>
      <View
        style={[
          stylesheet.listWrapper,
          { backgroundColor: theme.colors.background }
        ]}
      >
        <View style={stylesheet.flexHeader}>
          <DrawerButton />
          <Image
            source={themeName === "dark" ? logoDark : logoLight}
            style={stylesheet.logo}
          />
        </View>

        <View style={stylesheet.innerList}>
          {renderList()}
        </View>
      </View>

      <View style={stylesheet.mapWrapper}>
        {renderMap()}
      </View>
    </View>
  );
}
