// NOTE: Copied from auditaware/apps/case-web

import React, { FC, PropsWithChildren } from "react";
import {
  ApolloProvider,
} from "@apollo/client";
import { useApolloClientWithAuth } from "../../hooks/useApolloClientWithAuth";

interface AppApolloProviderProps extends PropsWithChildren {
  uri: string;
}

// NOTE: Candidate for extraction into @reasongcp/react-fire-sub.
const AppApolloProvider: FC<AppApolloProviderProps> = ({ children, uri }) => {
  const client = useApolloClientWithAuth(uri);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default AppApolloProvider;
