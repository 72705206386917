import { FC } from "react";
import { useAppTheme } from "../../theme";
import { terminalSubjectStatusColor } from "../../lib/mapUtils";
import Chip from "../shared/Chip";
import { SubjectStatus } from "../../types";

interface SubjectChipProps {
  status: SubjectStatus;
}

const TerminalSubjectStatusChip: FC<SubjectChipProps> = ({ status }) => {
  const theme = useAppTheme();
  const backgroundColor = terminalSubjectStatusColor(theme, status);

  return <Chip text={status} style={{ backgroundColor }} />;
};

export default TerminalSubjectStatusChip;
