import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { toggleStatus, selectStatuses } from "../../redux/residenceListSlice";

import { ResidenceCategories, ResidenceCategory } from "../../lib/types/residence";
import FilterButton from "../shared/FilterButton";

const options = ResidenceCategories.reduce((prev, next) => {
  prev[next] = next;
  return prev;
}, {} as Record<string, ResidenceCategory>);

const FilterResidencesButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const selected = useAppSelector(selectStatuses);

  const handleChange = useCallback((option: ResidenceCategory) => {
    dispatch(toggleStatus(option));
  }, [dispatch]);

  return (
    <FilterButton
      options={options}
      selected={selected}
      onChange={handleChange}
    />
  );
};

export default FilterResidencesButton;
