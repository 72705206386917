import { ApolloClient } from "@apollo/client";
import Constants from "expo-constants";

import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { SubjectStatuses } from "../../lib/types/subjectStatuses";
import exportSubjectToCaseManager from "../../lib/exportSubjectToCaseManager";
import { Business, Residence, Subject, User } from "../../types";

export type DismissResidenceCaseArgs = {
  orgId: string;
  epochId: string | undefined;
  residenceId: string;
};

const { caseManagerUrl = "https://qa-case.auditaware.app" } =
  Constants.expoConfig?.extra ?? {};

const dismissCase = (collectionPath: string, id: string) => {
  const docRef = doc(db, `${collectionPath}/${id}`);
  return updateDoc(docRef, {
    status: SubjectStatuses.DISMISSED,
  });
};

export const dismissBusinessCase = async ({
  orgId,
  businessId,
}: {
  orgId: string;
  businessId: string;
}) => {
  const collectionPath = `organizations/${orgId}/businesses`;
  return dismissCase(collectionPath, businessId);
};

export const dismissResidenceCase = async ({
  orgId,
  epochId,
  residenceId,
}: DismissResidenceCaseArgs) => {
  const collectionPath = `organizations/${orgId}/residence_epochs/${epochId}/residences`;
  return dismissCase(collectionPath, residenceId);
};

export const createBusinessCase = async ({
  orgId,
  business,
  user,
  apolloClient,
}: {
  orgId: string;
  business: Business;
  user: User;
  apolloClient: ApolloClient<object>;
}) => {
  const collectionPath = `organizations/${orgId}/businesses`;

  return exportSubjectToCaseManager({
    db,
    orgId,
    user,
    subject: business as Subject,
    business,
    apolloClient,
  })
    .then((result) => {
      const {
        case: { id: caseId },
      } = result;
      return `${caseManagerUrl}/cases/${caseId}`;
    })
    .then((href) => {
      const docRef = doc(db, `${collectionPath}/${business.id}`);
      return updateDoc(docRef, {
        status: SubjectStatuses.CASE_CREATED,
      }).then(() => href);
    });
};

export const createResidenceCase = async ({
  orgId,
  epochId,
  residence,
  user,
  apolloClient,
}: {
  orgId: string;
  epochId: string;
  residence: Residence;
  user: User;
  apolloClient: ApolloClient<object>;
}) => {
  const collectionPath = `organizations/${orgId}/residence_epochs/${epochId}/residences`;

  return exportSubjectToCaseManager({
    db,
    orgId,
    epochId,
    user,
    subject: residence as Subject,
    residence,
    apolloClient,
  })
    .then((result) => {
      const {
        case: { id: caseId },
      } = result;
      return `${caseManagerUrl}/cases/${caseId}`;
    })
    .then((href) => {
      const docRef = doc(db, `${collectionPath}/${residence.id}`);
      return updateDoc(docRef, {
        status: SubjectStatuses.CASE_CREATED,
      }).then(() => href);
    });
};
