import { FC } from "react";
import { BusinessCategory } from "../../lib/types/business";
import { useAppTheme } from "../../theme";
import { getBusinessStatusColor } from "../../lib/mapUtils";
import Chip from "./Chip";

interface BusinessChipProps {
  category: BusinessCategory;
}

const BusinessChip: FC<BusinessChipProps> = ({ category }) => {
  const theme = useAppTheme();
  const backgroundColor = getBusinessStatusColor(theme, category);

  return <Chip text={category} style={{ backgroundColor }} />;
};

export default BusinessChip;
