import { StyleSheet } from "react-native";

const stylesheet = StyleSheet.create({
  detailsContainer: {
    flexGrow: 1,
  },
  detailBlock: {
    marginTop: 12,
    paddingHorizontal: 12,
  },
});

export default stylesheet;
