import CommonMap from "./CommonMap";
import ResidencesMap from "./ResidencesMap";
import BusinessesMap from "./BusinessesMap";

const SubjectMap = Object.assign(CommonMap, {
  Residences: ResidencesMap,
  Businesses: BusinessesMap,
});

export default SubjectMap;
