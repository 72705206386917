import { useEffect } from "react";
import { View } from "react-native";
import { Text } from "react-native-paper";

import { Organization } from "../../types";
import { Residence } from "../../lib/types/residence";
import NoData from "../shared/NoData";

import { selectNotes, subscribeResidenceNotesToFirestore } from "../../redux/notesSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import residenceStyles from "../DetailStyles";

type NotesProps = {
  organization: Organization;
  residence: Residence;
};

const ResidenceNotes: React.FC<NotesProps> = ({
  organization,
  residence,
}) => {
  const dispatch = useAppDispatch();
  const notes = useAppSelector(selectNotes);

  useEffect(() => {
    const sub = dispatch(
      subscribeResidenceNotesToFirestore(organization, residence.id),
    );
    return () => sub.unsubscribe();
  }, [dispatch, organization, residence])

  return (
    <View style={residenceStyles.detailBlock}>
      {notes.length === 0 && <NoData text="No Notes" />}
      {notes.map((note) => {
        return <Text key={note.uuid}>{note.text}</Text>;
      })}
    </View>
  );
};

export default ResidenceNotes;
