import { FC } from "react";
import { Text } from "react-native-paper";

interface DisplayLabelProps {
  text: string;
}

const DisplayLabel: FC<DisplayLabelProps> = ({ text }) => {
  return (
    <Text
      style={{
        fontSize: 14,
        marginTop: 6,
        marginBottom: 6,
        fontWeight: "700",
      }}
    >
      { text }
    </Text>
  );
}

export default DisplayLabel;
