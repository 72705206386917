import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { selectSearchText, setSearchText } from "../../redux/residenceListSlice";

import SearchBox from "../shared/SearchBox";

const Search: React.FC = () => {
  const dispatch = useAppDispatch();

  const searchText = useAppSelector(selectSearchText);

  const handleChange = useCallback((text: string) => {
    dispatch(setSearchText(text));
  }, [dispatch]);

  return (
    <SearchBox
      initialValue={searchText}
      onChange={handleChange}
    />
  );
};

export default Search;
