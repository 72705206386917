import ResidenceDetails from "./ResidenceDetails";

import SubjectNavigator from "./SubjectNavigator";
import ResidencesList from "./ResidencesList";

const ResidenceListNavigation = () => {
  return (
    <SubjectNavigator
      subjectType="Residence"
      listComponent={ResidencesList}
      detailComponent={ResidenceDetails}
    />
  );
};

export default ResidenceListNavigation;
