import { StyleSheet } from "react-native";

const notesAttachmentsStyles = StyleSheet.create({
  tabViewContainer: {
    minHeight: 500,
  },
  tabView: {
    minHeight: 500,
  },
  tabNav: {
    minHeight: 500,
    marginTop: 24,
  },
  tabScreen: {
    backgroundColor: "transparent",
  },
});

export default notesAttachmentsStyles;
