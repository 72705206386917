import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";

import { RootStackParamList } from "../lib/appNavigation";

export type AppNavigation = StackNavigationProp<RootStackParamList>;

const useAppNavigation = () => useNavigation<AppNavigation>();

export default useAppNavigation;
