import { FC } from "react";
import { Text } from "react-native";
import { Assignee, ASSIGNEE_UNASSIGNED } from "../../lib/types/assignee";
import { useAppTheme } from "../../theme";

type AssigneeTextProps = {
  assignee: Assignee | null;
};

export const AssigneeText: FC<AssigneeTextProps> = ({ assignee }) => {
  const {
    colors: { onBackground },
  } = useAppTheme();
  const hasAssignee = assignee && assignee.displayName;
  const fontStyle = hasAssignee ? "normal" : "italic";
  const displayName = hasAssignee
    ? `Assignee: ${assignee.displayName}`
    : ASSIGNEE_UNASSIGNED;

  return <Text style={{ fontStyle, color: onBackground }}>{displayName}</Text>;
};
